import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import withRouter from "../../Components/Common/withRouter";
import logoLight from "../../assets/images/logo-light.png";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const steps = [
  { number: 1, title: "Datos Personales" },
  { number: 2, title: "Dirección" },
  { number: 3, title: "Verificación" },
  { number: 4, title: "Completado" },
];

const KycWizard = () => {
  document.title = "KYC | Nautt Finance";
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [kycData, setKycData] = useState({
    uuid: uuid || "",
    email: "",
    pais: "",
    numero_telefone: "",
    checkbox_privacidade: false,
    endereco: "",
    codigo_postal: "",
    cidade: "",
    provincia: "",
    status: "em-andamento",
  });

  const fetchKycData = useCallback(async () => {
    if (!uuid) {
      setLoading(false);
      return;
    }

    // Se uuid for "concluido", vá direto para o step 4
    if (uuid === "concluido") {
      setCurrentStep(4);
      setLoading(false);
      return;
    }

    try {
      const data = {
        uuid: uuid,
      };
      const response = await apiClient.get(`/kyc/get`, data);
      if (response.sucesso) {
        const responseData = response.dados;
        setKycData(prev => ({
          ...prev,
          uuid: responseData.kyc_code,
          email: responseData.email,
          pais: responseData.country_code,
          numero_telefone: responseData.telefone,
          checkbox_privacidade: responseData.checkbox_privacidade,
          endereco: responseData.endereco,
          codigo_postal: responseData.codigo_postal,
          cidade: responseData.cidade,
          provincia: responseData.provincia,
          status: responseData.status || "em-andamento",
        }));

        // Determine which step to show based on filled fields
        if (responseData.veriff_id && responseData.status) {
          setCurrentStep(4); // Ir para o passo de conclusão somente se tiver veriff_id E status
        } else if (responseData.endereco && responseData.codigo_postal && responseData.cidade && responseData.provincia) {
          setCurrentStep(3); // Se todos os dados de endereço estão preenchidos, vá para o step 3
        } else if (responseData.email && responseData.country_code && responseData.telefone && responseData.checkbox_privacidade) {
          setCurrentStep(2); // Se os dados básicos estão preenchidos, vá para o step 2
        }
      } else {
        toast.error(response.mensagem || "Error al cargar los datos KYC");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al cargar los datos KYC");
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  useEffect(() => {
    fetchKycData();
  }, [fetchKycData]);

  const handleStepComplete = (stepData) => {
    setKycData((prev) => ({ ...prev, ...stepData }));
    setCurrentStep((prev) => prev + 1);
  };

  const renderStep = () => {
    if (loading) {
      return (
        <div className="text-center">
          <Spinner color="success" />
          <p className="mt-2">Cargando datos KYC...</p>
        </div>
      );
    }

    switch (currentStep) {
      case 1:
        return <StepOne onComplete={handleStepComplete} data={kycData} />;
      case 2:
        return <StepTwo onComplete={handleStepComplete} data={kycData} />;
      case 3:
        return <StepThree onComplete={handleStepComplete} data={kycData} />;
      case 4:
        return <StepFour status={uuid === "concluido" ? "em-andamento" : kycData.status} />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="50" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={8}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-success">Verificación KYC</h5>
                      {currentStep < 4 && (
                        <p className="text-muted">Complete los pasos para verificar su cuenta</p>
                      )}
                    </div>

                    {/* Step Indicators - Só mostrar se não estiver no passo 4 */}
                    {currentStep < 4 && (
                      <div className="d-flex justify-content-center mb-4">
                        {steps.slice(0, 3).map((step, index) => (
                          <div
                            key={step.number}
                            className="d-flex align-items-center"
                          >
                            <div
                              className={`d-flex align-items-center justify-content-center rounded-circle ${
                                step.number === currentStep
                                  ? "bg-success text-white"
                                  : step.number < currentStep
                                  ? "bg-success-subtle text-success"
                                  : "bg-light text-muted me-2"
                              }`}
                              style={{
                                width: "32px",
                                height: "32px",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              {step.number}
                            </div>
                            {step.number === currentStep && (
                              <span
                                className="ms-2 me-2 text-success fw-medium"
                                style={{ fontSize: "14px" }}
                              >
                                {step.title}
                              </span>
                            )}
                            {index < 2 && (
                              <div
                                className={`flex-grow-1 ms-2 me-2 ${
                                  step.number < currentStep
                                    ? "border-success"
                                    : "border-light"
                                }`}
                                style={{
                                  height: "2px",
                                  background: step.number < currentStep ? "#0ab39c" : "#f3f3f9",
                                  width: "60px",
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    )}

                    {renderStep()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(KycWizard);
