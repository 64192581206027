import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";

const ModalEditarConta = ({ modal, setModal, conta, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [moedas, setMoedas] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [selectedMoeda, setSelectedMoeda] = useState("");
  const [formData, setFormData] = useState({
    nome: "",
    banco: "",
    agencia: "",
    conta: "",
    pix_alias: "",
    telefone: "",
    nome_destinatario: "",
    primeiro_sobrenome: "",
    data_nascimento: "",
    atividade: "",
    documento: "",
    pais: "",
    moeda: "",
    tipo: "fisica",
    email: "",
    endereco: "",
    provincia: "",
    cidade: "",
    codigo_postal: "",
    routing_number: "",
    iban: "",
    swift_bic: "",
  });
  const [errors, setErrors] = useState({});

  const fetchMoedas = useCallback(async () => {
    setLoading(true);
    const apiClient = new APIClient();
    const response = await apiClient.get("/moedas", { bancos: true });
    if (response.sucesso) {
      const dados = response.dados.filter(moeda => 
        moeda.tipo_documento && 
        moeda.bancos && 
        moeda.bancos.length > 0
      );
      setMoedas(dados);

      // Se temos uma conta, encontre e selecione a moeda correspondente
      if (conta) {
        const moedaAtual = dados.find(m => m.country_code === conta.pais);
        if (moedaAtual) {
          setSelectedMoeda(moedaAtual.country_code);
          setBancos(moedaAtual.bancos);
        }
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, [conta]);

  useEffect(() => {
    if (conta) {
      setFormData({
        nome: conta.nome || "",
        banco: conta.banco || "",
        agencia: conta.agencia || "",
        conta: conta.conta || "",
        pix_alias: conta.pix_alias || "",
        telefone: conta.telefone || "",
        nome_destinatario: conta.nome_destinatario || "",
        primeiro_sobrenome: conta.primeiro_sobrenome || "",
        data_nascimento: conta.data_nascimento || "",
        atividade: conta.atividade || "",
        documento: conta.documento || "",
        pais: conta.pais || "",
        moeda: conta.moeda || "",
        tipo: conta.tipo || "fisica",
        email: conta.email || "",
        endereco: conta.endereco || "",
        provincia: conta.provincia || "",
        cidade: conta.cidade || "",
        codigo_postal: conta.codigo_postal || "",
        routing_number: conta.routing_number || "",
        iban: conta.iban || "",
        swift_bic: conta.swift_bic || "",
      });
    }
    fetchMoedas();
  }, [conta, fetchMoedas]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'moeda') {
      setSelectedMoeda(value);
      const moedaSelecionada = moedas.find(m => m.country_code === value);
      if (moedaSelecionada) {
        if (moedaSelecionada?.bancos) {
          setBancos(moedaSelecionada?.bancos);
        } else {
          setBancos([]);
        }
        setFormData(prev => ({
          ...prev,
          pais: moedaSelecionada?.country_code,
          moeda: moedaSelecionada?.sigla
        }));
      }
    } else if (name === "documento") {
      const mask = getDocumentoMask();
      if (mask === "#") {
        // Validar com regex para máscara "#"
        if (value === "" || /^[0-9]{1,15}$/.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [name]: value.toUpperCase(),
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      }
    } else if (name === "telefone") {
      const mask = getTelefoneMask();
      if (mask === "#") {
        // Validar com regex para máscara "#"
        if (value === "" || /^[0-9]{1,15}$/.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [name]: value.toUpperCase(),
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      }
    } else if (name === "codigo_postal") {
      const mask = getCepMask();
      if (mask === "#") {
        // Validar com regex para máscara "#"
        if (value === "" || /^[0-9]{1,15}$/.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [name]: value.toUpperCase(),
          }));
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value.toUpperCase(),
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear error when field is modified
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const apiClient = new APIClient();
    const response = await apiClient.put("/contas-cliente", {
      ...formData,
      conta_id: conta.id,
    });

    if (response.sucesso) {
      toast.success("Cuenta bancaria actualizada con éxito");
      setRefresh(true);
      setModal(false);
    } else {
      toast.error(response.mensagem || "Error al actualizar la cuenta bancaria");
    }

    setLoading(false);
  };

  const isBrazil = selectedMoeda === "BRA";
  const isPixProvided = formData.pix_alias?.trim().length > 0;
  const isPessoaFisica = formData.tipo === "fisica";

  const getDocumentoLabel = () => {
    const moedaSelecionada = moedas.find(m => m.country_code === selectedMoeda);
    if (!moedaSelecionada || !moedaSelecionada.tipo_documento) return "Documento";
    return isPessoaFisica ? moedaSelecionada.tipo_documento.doc_pf : moedaSelecionada.tipo_documento.doc_pj;
  };

  const getDocumentoMask = () => {
    const moedaSelecionada = moedas.find(m => m.country_code === selectedMoeda);
    if (!moedaSelecionada || !moedaSelecionada.tipo_documento) return "";
    return isPessoaFisica ? moedaSelecionada.tipo_documento.mask_pf : moedaSelecionada.tipo_documento.mask_pj;
  };

  const getDocumentoPlaceholder = () => {
    const mask = getDocumentoMask();
    return mask.replace(/9/g, "0");
  };

  const getTelefoneMask = () => {
    const moedaSelecionada = moedas.find(
      (m) => m.country_code === selectedMoeda
    );
    if (!moedaSelecionada || !moedaSelecionada?.tipo_documento) return "";
    return moedaSelecionada?.tipo_documento.mask_tel;
  };

  const getTelefonePlaceholder = () => {
    const mask = getTelefoneMask();
    return mask ? mask.replace(/9/g, "0") : "";
  };

  const getCepMask = () => {
    const moedaSelecionada = moedas.find(
      (m) => m.country_code === selectedMoeda
    );
    if (!moedaSelecionada || !moedaSelecionada?.tipo_documento) return "";
    return moedaSelecionada?.tipo_documento.mask_cep;
  };

  const getCepPlaceholder = () => {
    const mask = getCepMask();
    return mask ? mask.replace(/9/g, "0") : "";
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)} centered size="lg">
      <ModalHeader toggle={() => setModal(false)}>
        Editar Cuenta Bancaria
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="moeda">Moneda</Label>
                <Input
                  type="select"
                  name="moeda"
                  id="moeda"
                  value={selectedMoeda}
                  onChange={handleInputChange}
                  disabled
                >
                  <option value="">Seleccionar moneda</option>
                  {moedas.map((moeda) => (
                    <option key={moeda.country_code} value={moeda.country_code}>
                      {moeda.sigla}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="tipo">Tipo de Persona</Label>
                <Input
                  type="select"
                  name="tipo"
                  id="tipo"
                  value={formData.tipo}
                  onChange={handleInputChange}
                  disabled
                >
                  <option value="fisica">Persona Física</option>
                  <option value="juridica">Persona Jurídica</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="documento">{getDocumentoLabel()}</Label>
                <InputMask
                  mask={getDocumentoMask()}
                  value={formData.documento}
                  onChange={handleInputChange}
                  className={`form-control ${
                    errors.documento ? "is-invalid" : ""
                  }`}
                  placeholder={getDocumentoPlaceholder()}
                  disabled
                >
                  {(inputProps) => (
                    <Input {...inputProps} type="text" name="documento" />
                  )}
                </InputMask>
                {errors.documento && (
                  <FormFeedback>{errors.documento}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="nome">Nombre de la Cuenta</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  placeholder="Nombre de la cuenta"
                  value={formData.nome}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email del destinatario"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="telefone">Teléfono</Label>
                {getTelefoneMask() === "#" ? (
                  <Input
                    type="text"
                    name="telefone"
                    id="telefone"
                    value={formData.telefone}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.telefone ? "is-invalid" : ""
                    }`}
                    maxLength={15}
                    required
                  />
                ) : (
                  <InputMask
                    mask={getTelefoneMask()}
                    value={formData.telefone}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.telefone ? "is-invalid" : ""
                    }`}
                    placeholder={getTelefonePlaceholder()}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="telefone"
                        id="telefone"
                        required
                      />
                    )}
                  </InputMask>
                )}
                {errors.telefone && (
                  <FormFeedback>{errors.telefone}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="nome_destinatario">Nombre del Destinatario</Label>
                <Input
                  type="text"
                  name="nome_destinatario"
                  id="nome_destinatario"
                  placeholder="Nombre del destinatario"
                  value={formData.nome_destinatario}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="primeiro_sobrenome">Primer Apellido</Label>
                <Input
                  type="text"
                  name="primeiro_sobrenome"
                  id="primeiro_sobrenome"
                  placeholder="Primer apellido"
                  value={formData.primeiro_sobrenome}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="data_nascimento">Fecha de Nacimiento</Label>
                <Input
                  type="date"
                  name="data_nascimento"
                  id="data_nascimento"
                  value={formData.data_nascimento}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="atividade">Actividad</Label>
                <Input
                  type="text"
                  name="atividade"
                  id="atividade"
                  placeholder="Actividad"
                  value={formData.atividade}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <h5>Dirección</h5>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="endereco">Dirección</Label>
                <Input
                  type="text"
                  name="endereco"
                  id="endereco"
                  placeholder="Dirección completa"
                  value={formData.endereco}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="provincia">Provincia</Label>
                <Input
                  type="text"
                  name="provincia"
                  id="provincia"
                  placeholder="Provincia"
                  value={formData.provincia}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="cidade">Ciudad</Label>
                <Input
                  type="text"
                  name="cidade"
                  id="cidade"
                  placeholder="Ciudad"
                  value={formData.cidade}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="codigo_postal">Código Postal</Label>
                {getCepMask() === "#" ? (
                  <Input
                    type="text"
                    name="codigo_postal"
                    id="codigo_postal"
                    value={formData.codigo_postal}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.codigo_postal ? "is-invalid" : ""
                    }`}
                    maxLength={15}
                    required
                  />
                ) : (
                  <InputMask
                    mask={getCepMask()}
                    value={formData.codigo_postal}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.codigo_postal ? "is-invalid" : ""
                    }`}
                    placeholder={getCepPlaceholder()}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="codigo_postal"
                        id="codigo_postal"
                        required
                      />
                    )}
                  </InputMask>
                )}
                {errors.codigo_postal && (
                  <FormFeedback>{errors.codigo_postal}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <h5>Datos Bancarios</h5>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="banco">Banco</Label>
                {bancos.length === 0 ? (
                  <Input
                    type="text"
                    name="banco"
                    id="banco"
                    value={formData.banco}
                    onChange={handleInputChange}
                    maxLength={100}
                    placeholder="Nombre del banco"
                    required
                  />
                ) : (
                  <Input
                    type="select"
                    name="banco"
                    id="banco"
                    value={formData.banco}
                    onChange={handleInputChange}
                  >
                    <option value="">Seleccionar banco</option>
                    {bancos.map((banco) => (
                      <option key={banco.id} value={banco.name}>
                        {banco.bankCode === ""
                          ? banco.name
                          : `${banco.bankCode} - ${banco.name}`}
                      </option>
                    ))}
                  </Input>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              {formData.moeda !== "USD" ? (
                <FormGroup>
                  <Label for="pix_alias">
                    {isBrazil ? "Chave PIX" : "CBU / CVU / ALIAS"}
                  </Label>
                  <Input
                    type="text"
                    name="pix_alias"
                    id="pix_alias"
                    placeholder={
                      isBrazil
                        ? "Ingrese la clave PIX"
                        : "Ingrese CBU/CVU/ALIAS"
                    }
                    value={formData.pix_alias}
                    onChange={handleInputChange}
                  />
                  {isBrazil && (
                    <small className="text-muted">
                      Si informa la clave PIX, agencia, cuenta y banco no son
                      obligatorios
                    </small>
                  )}
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="conta">Cuenta</Label>
                  <Input
                    type="text"
                    name="conta"
                    id="conta"
                    value={formData.conta}
                    onChange={handleInputChange}
                    placeholder="Número de cuenta"
                    maxLength={30}
                    required
                  />
                </FormGroup>
              )}
            </Col>
          </Row>

          {isBrazil && (
            <>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="agencia">Agencia</Label>
                    <Input
                      type="text"
                      name="agencia"
                      id="agencia"
                      placeholder="Número de agencia"
                      value={formData.agencia}
                      onChange={handleInputChange}
                      required={!isPixProvided}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="conta">Cuenta</Label>
                    <Input
                      type="text"
                      name="conta"
                      id="conta"
                      placeholder="Número de cuenta"
                      value={formData.conta}
                      onChange={handleInputChange}
                      required={!isPixProvided}
                      maxLength={30}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <div className="text-end mt-4">
            <Button
              type="button"
              color="light"
              onClick={() => setModal(false)}
              className="me-2"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <Spinner size="sm" /> : "Guardar"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditarConta;
