import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const StepFour = ({ status = 'em-andamento' }) => {
  const getStatusContent = () => {
    switch (status) {
      case 'verificado':
        return {
          icon: "check-circle",
          iconColor: "text-success",
          title: "¡Verificación exitosa!",
          message: "Su cuenta ha sido verificada exitosamente. Ya puede acceder a todas las funcionalidades de Nautt Finance.",
        };
      case 'negado':
        return {
          icon: "x-circle",
          iconColor: "text-danger",
          title: "Verificación denegada",
          message: "Lo sentimos, su verificación KYC ha sido denegada. Por favor, contacte con nuestro soporte para más información.",
        };
      case 'em-andamento':
      default:
        return {
          icon: "clock",
          iconColor: "text-warning",
          title: "¡Verificación completada!",
          message: "Sus datos han sido enviado con éxito! Espere el contacto de nuestro equipo. En hasta 72 horas, recibiras en el mail cadastrado tus llaves de acesso al sistema de Nautt Finance!",
        };
    }
  };

  const content = getStatusContent();

  return (
    <div className="text-center pt-4">
      <div className="mb-4">
        <FeatherIcon
          icon={content.icon}
          className={content.iconColor}
          style={{ width: "64px", height: "64px" }}
        />
      </div>
      <h4 className={content.iconColor + " mb-3"}>{content.title}</h4>
      <p className="text-muted mb-4">
        {content.message}
      </p>
      <Button
        color={status === 'negado' ? "danger" : "success"}
        tag={Link}
        to="/login"
        className="btn-hover"
      >
        Ir al inicio de sesión
      </Button>
    </div>
  );
};

export default StepFour;
