import React from "react";
import { Container, Row, Col, Card, CardBody, Badge, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const QrCodePix = () => {
  document.title = "API QR Code Pix | Nautt Finance";

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname.includes("sandbox")) {
      return "https://api-sandbox.nautt.finance/v1";
    } else {
      return "https://api.nautt.finance/v1";
    }
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copiado al portapapeles");
    } catch (error) {
      toast.error("Error al copiar al portapapeles");
    }
  };

  const baseUrl = getBaseUrl();

  const curlCodeGenerate = `curl -i -X POST ${baseUrl}/gerar-qr \\
    -H "Content-Type: application/json" \\
    -d '{
        "total_brl": 100,
        "expiracao": 600,
        "pdv_id": 1,
        "atendente_id": 1
    }'`;
  
    const curlCodeGenerateSinpdv = `curl -i -X POST ${baseUrl}/gerar-qr \\
    -H "Authorization: Bearer <YOUR_TOKEN>" \\
    -H "Content-Type: application/json" \\
    -d '{
        "total_brl": 100,
        "expiracao": 600
    }'`;

  const curlCodeView = `curl -i -X GET "${baseUrl}/ordem?ordem_id=1" \\
    -H "Authorization: Bearer <YOUR_TOKEN>" \\
    -H "Content-Type: application/json"`;

  const generateSuccessResponse = `{
    "mensagem": "QR Code Pix generado con éxito",
    "dados": {
        "id": 1,
        "status": "nuevo",
        "qrcode": "IVfnjbvvbeo758Glndlandhigvojjohfyyebvbvo"
    }
}`;

  const viewSuccessResponse = `{
    "mensagem": "Orden Pix encontrada con éxito",
    "dados": {
        "id": 1,
        "status": "nuevo",
        "qrcode": "IVfnjbvvbeo758Glndlandhigvojjohfyyebvbvo",
        "created_at": "2024-01-01T00:00:00.000000Z"
    }
}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="QR Code Pix" pageTitle="API" />

          {/* Generar QR Code Pix */}
          <Row className="mb-4">
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Generar QR Code Pix (Brazil)</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="primary" className="fs-5">
                          POST
                        </Badge>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>
                        Genera un QR Code Pix a partir de los dados
                        proporcionados. El PDV ID y el Atendente ID son
                        opcionais, pero se no les proporcionados, es necesario
                        proporcionar el Bearer Token para poder identificar al
                        comerciante.
                      </p>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> QR Code generado
                          con éxito.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge> Token
                          inválido o expirado.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "total_brl": 100,
    "expiracao": 600,
    "pdv_id": 1,
    "atendente_id": 1
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {generateSuccessResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL con PDV</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeGenerate)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeGenerate}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL sin PDV</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeGenerateSinpdv)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeGenerateSinpdv}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Visualizar Orden Pix */}
          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="live-preview">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1">
                        <h2 className="mb-0">Visualizar Orden Pix</h2>
                      </div>
                      <div className="flex-shrink-0">
                        <Badge color="success" className="fs-5">
                          GET
                        </Badge>
                      </div>
                    </div>

                    <div className="mb-4">
                      <h5>Descripción</h5>
                      <p>Retorna los detalles de una orden pix específica</p>
                    </div>

                    <div className="mb-4">
                      <h5>Retornos</h5>
                      <ul className="mb-0">
                        <li>
                          <Badge color="success">200 OK</Badge> Datos retornados
                          con éxito.
                        </li>
                        <li>
                          <Badge color="danger">401 Unauthorized</Badge> Token
                          inválido o expirado.
                        </li>
                        <li>
                          <Badge color="danger">404 Not Found</Badge> Credencial
                          no encontrada.
                        </li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      <h5>Solicitud (Query Parameters)</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {`{
    "ordem_id": 1
}`}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>

                    <div className="mb-0">
                      <h5>Respuesta Exitosa</h5>
                      <Card className="bg-light">
                        <CardBody className="p-0">
                          <SyntaxHighlighter
                            language="json"
                            style={atomOneDark}
                            customStyle={{
                              padding: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {viewSuccessResponse}
                          </SyntaxHighlighter>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Ejemplo cURL</h5>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => copyToClipboard(curlCodeView)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                  <Card className="bg-light">
                    <CardBody className="p-0">
                      <SyntaxHighlighter
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.5rem",
                          margin: "0",
                        }}
                        wrapLines={true}
                      >
                        {curlCodeView}
                      </SyntaxHighlighter>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QrCodePix;
