import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";

const apiClient = new APIClient();

const StepOne = ({ onComplete, data }) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    email: data.email || "",
    pais: data.pais || "",
    numero_telefone: data.numero_telefone || "",
    primeiro_sobrenome: data.primeiro_sobrenome || "",
    atividade: data.atividade || "",
    checkbox_privacidade: data.checkbox_privacidade || false,
  });
  const [errors, setErrors] = useState({});

  const fetchCountries = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;
      setCountries(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const getPhoneMask = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais
    );
    return selectedCountry?.tipo_documento?.mask_tel || "";
  };

  const getDDI = () => {
    const selectedCountry = countries.find(
      (country) => country.country_code === formData.pais
    );
    return selectedCountry?.tipo_documento?.ddi || "";
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "El email es obligatorio";
    if (!formData.pais) newErrors.pais = "El país es obligatorio";
    if (!formData.numero_telefone) newErrors.numero_telefone = "El teléfono es obligatorio";
    if (!formData.primeiro_sobrenome) newErrors.primeiro_sobrenome = "El primer apellido es obligatorio";
    if (!formData.atividade) newErrors.atividade = "La actividad es obligatoria";
    if (!formData.checkbox_privacidade) newErrors.checkbox_privacidade = "Debe aceptar la política de privacidad";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const payload = {
        email: formData.email,
        pais: formData.pais,
        numero_telefone: `${getDDI()}${formData.numero_telefone.replace(/\D/g, "")}`,
        primeiro_sobrenome: formData.primeiro_sobrenome,
        atividade: formData.atividade,
        checkbox_privacidade: formData.checkbox_privacidade,
      };

      const response = await apiClient.post("/kyc", payload);
      if (response.sucesso) {
        onComplete({ ...formData, uuid: response.dados.kyc_code });
      } else {
        toast.error(response.mensagem || "Error al guardar los datos");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al guardar los datos");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleInputChange}
          invalid={!!errors.email}
        />
        <FormFeedback>{errors.email}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="primeiro_sobrenome">Primer Apellido</Label>
        <Input
          type="text"
          name="primeiro_sobrenome"
          id="primeiro_sobrenome"
          value={formData.primeiro_sobrenome}
          onChange={handleInputChange}
          invalid={!!errors.primeiro_sobrenome}
          maxLength={100}
        />
        <FormFeedback>{errors.primeiro_sobrenome}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="atividade">Actividad</Label>
        <Input
          type="text"
          name="atividade"
          id="atividade"
          value={formData.atividade}
          onChange={handleInputChange}
          invalid={!!errors.atividade}
          maxLength={100}
        />
        <FormFeedback>{errors.atividade}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="pais">País Fiscal</Label>
        <Input
          type="select"
          name="pais"
          id="pais"
          value={formData.pais}
          onChange={handleInputChange}
          invalid={!!errors.pais}
        >
          <option value="">Seleccione un país</option>
          {countries.map((country) => (
            <option key={country.country_code} value={country.country_code}>
              {country.pais}
            </option>
          ))}
        </Input>
        <FormFeedback>{errors.pais}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="numero_telefone">Teléfono</Label>
        <InputMask
          mask={getPhoneMask()}
          value={formData.numero_telefone}
          onChange={handleInputChange}
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              type="text"
              name="numero_telefone"
              id="numero_telefone"
              invalid={!!errors.numero_telefone}
            />
          )}
        </InputMask>
        <FormFeedback>{errors.numero_telefone}</FormFeedback>
      </FormGroup>

      <FormGroup check className="mb-4">
        <Input
          type="checkbox"
          name="checkbox_privacidade"
          id="checkbox_privacidade"
          checked={formData.checkbox_privacidade}
          onChange={handleInputChange}
          invalid={!!errors.checkbox_privacidade}
        />
        <Label check for="checkbox_privacidade">
          Acepto la política de privacidad
        </Label>
        <FormFeedback>{errors.checkbox_privacidade}</FormFeedback>
      </FormGroup>

      <div className="mt-4">
        <Button
          color="success"
          className="w-100"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Continuar"}
        </Button>
      </div>
    </Form>
  );
};

export default StepOne;
