import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import InputMask from "react-input-mask";

const apiClient = new APIClient();

const StepTwo = ({ onComplete, data }) => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    endereco: data.endereco || "",
    codigo_postal: data.codigo_postal || "",
    cidade: data.cidade || "",
    provincia: data.provincia || "",
    pais_endereco: data.pais_endereco || "",
  });
  const [errors, setErrors] = useState({});

  const fetchCountries = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      setCountries(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const getSelectedCountry = () => {
    return countries.find(
      (country) => country.country_code === formData.pais_endereco
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "codigo_postal" ? value.toUpperCase() : value,
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.endereco) newErrors.endereco = "La dirección es obligatoria";
    if (!formData.codigo_postal) newErrors.codigo_postal = "El código postal es obligatorio";
    if (!formData.cidade) newErrors.cidade = "La ciudad es obligatoria";
    if (!formData.provincia) newErrors.provincia = "La provincia es obligatoria";
    if (!formData.pais_endereco) newErrors.pais_endereco = "El país es obligatorio";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const payload = {
        uuid: data.uuid,
        endereco: formData.endereco,
        codigo_postal: formData.codigo_postal,
        cidade: formData.cidade,
        provincia: formData.provincia,
        pais_endereco: formData.pais_endereco,
      };

      const response = await apiClient.put("/kyc", payload);
      if (response.sucesso) {
        onComplete(formData);
      } else {
        toast.error(response.mensagem || "Error al actualizar los datos de dirección");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al procesar la solicitud");
    }
    setLoading(false);
  };

  const selectedCountry = getSelectedCountry();

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="pais_endereco">País de Residencia</Label>
        <Input
          type="select"
          name="pais_endereco"
          id="pais_endereco"
          value={formData.pais_endereco}
          onChange={handleInputChange}
          invalid={!!errors.pais_endereco}
        >
          <option value="">Seleccione un país</option>
          {countries.map((country) => (
            <option key={country.country_code} value={country.country_code}>
              {country.pais}
            </option>
          ))}
        </Input>
        <FormFeedback>{errors.pais_endereco}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="endereco">Dirección</Label>
        <Input
          type="text"
          name="endereco"
          id="endereco"
          value={formData.endereco}
          onChange={handleInputChange}
          invalid={!!errors.endereco}
        />
        <FormFeedback>{errors.endereco}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="codigo_postal">Código Postal</Label>
        <InputMask
          mask={selectedCountry?.tipo_documento?.mask_cep || ""}
          value={formData.codigo_postal}
          onChange={handleInputChange}
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              type="text"
              name="codigo_postal"
              id="codigo_postal"
              invalid={!!errors.codigo_postal}
            />
          )}
        </InputMask>
        <FormFeedback>{errors.codigo_postal}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="cidade">Ciudad</Label>
        <Input
          type="text"
          name="cidade"
          id="cidade"
          value={formData.cidade}
          onChange={handleInputChange}
          invalid={!!errors.cidade}
        />
        <FormFeedback>{errors.cidade}</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for="provincia">Provincia</Label>
        <Input
          type="text"
          name="provincia"
          id="provincia"
          value={formData.provincia}
          onChange={handleInputChange}
          invalid={!!errors.provincia}
        />
        <FormFeedback>{errors.provincia}</FormFeedback>
      </FormGroup>

      <div className="mt-4">
        <Button
          color="success"
          className="w-100"
          type="submit"
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Continuar"}
        </Button>
      </div>
    </Form>
  );
};

export default StepTwo;
