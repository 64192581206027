import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Alert,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";

const CreateCredentialModal = ({
  isOpen,
  toggle,
  newCredential,
  loading,
  onCreateCredential,
  pdvs = [],
}) => {
  const [selectedPdv, setSelectedPdv] = useState("");

  const toggleModal = () => {
    setSelectedPdv("");
    toggle();
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copiado al portapapeles");
    } catch (error) {
      toast.error("Error al copiar al portapapeles");
    }
  };

  const handleCreate = () => {
    const pdvId = selectedPdv ? parseInt(selectedPdv) : null;
    onCreateCredential(pdvId);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered size="lg">
      <ModalHeader toggle={toggleModal}>
        {newCredential ? "Credencial Creada" : "Nueva Credencial"}
      </ModalHeader>
      <ModalBody>
        {newCredential ? (
          <div>
            <Alert color="warning" className="mb-4">
              <FeatherIcon icon="alert-triangle" className="me-2" />
              <strong>¡IMPORTANTE!</strong> El Client Secret se mostrará solo
              una vez. Guárdelo en un lugar seguro, ya que no podrá recuperarlo
              más tarde.
            </Alert>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className="fw-bold">Client ID</Label>
                  <div className="input-group">
                    <Input
                      type="text"
                      value={newCredential.client_id}
                      readOnly
                    />
                    <Button
                      color="primary"
                      onClick={() => copyToClipboard(newCredential.client_id)}
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="fw-bold">Client Secret</Label>
                  <div className="input-group">
                    <Input
                      type="text"
                      value={newCredential.client_secret}
                      readOnly
                    />
                    <Button
                      color="primary"
                      onClick={() =>
                        copyToClipboard(newCredential.client_secret)
                      }
                    >
                      <FeatherIcon icon="copy" size={14} />
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <div className="text-end mt-4">
              <Link
                className="btn btn-link link-primary fw-medium"
                onClick={toggleModal}
              >
                <i className="ri-close-line me-1 align-middle"></i> Cerrar
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <p>¿Está seguro de que desea crear una nueva credencial de API?</p>

            {/* {pdvs.length > 0 && (
              <FormGroup className="mb-3">
                <Label for="pdvSelect">Punto de Venta (Opcional)</Label>
                <Input
                  id="pdvSelect"
                  type="select"
                  value={selectedPdv}
                  onChange={(e) => setSelectedPdv(e.target.value)}
                >
                  <option value="">Seleccionar PDV...</option>
                  {pdvs.map((pdv) => (
                    <option key={pdv.id} value={pdv.id}>
                      {pdv.nome}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )} */}

            <div className="text-end">
              <Link
                to="#"
                className="btn btn-link link-primary fw-medium"
                onClick={() => toggle()}
                disabled={loading}
              >
                <i className="ri-close-line me-1 align-middle"></i> Cerrar
              </Link>
              <Button color="primary" onClick={handleCreate} disabled={loading}>
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" />
                    Creando...
                  </>
                ) : (
                  "Crear Credencial"
                )}
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default CreateCredentialModal;
