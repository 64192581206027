import React from "react";
import { Row, Col, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import InputMask from "react-input-mask";

const UsdFields = ({ formData, handleInputChange, errors = {} }) => {
  const handleUpperCaseInput = (e, field) => {
    const value = e.target.value.toUpperCase();
    handleInputChange({
      target: {
        name: field,
        value
      }
    });
  };

  const validateAlphanumeric = (value, maxLength) => {
    return value === "" || /^[A-Z0-9]{1,${maxLength}}$/.test(value);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label for="endereco">Dirección</Label>
            <Input
              type="textarea"
              name="endereco"
              id="endereco"
              value={formData.endereco || ""}
              onChange={handleInputChange}
              maxLength={300}
              placeholder="Dirección completa"
              required
              className={`form-control ${errors.endereco ? "is-invalid" : ""}`}
            />
            {errors.endereco && (
              <FormFeedback>{errors.endereco}</FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="telefone">Teléfono</Label>
            <InputMask
              mask="+1 (999) 999-9999"
              value={formData.telefone || ""}
              onChange={handleInputChange}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  type="text"
                  name="telefone"
                  id="telefone"
                  placeholder="Número de teléfono"
                  required
                  className={`form-control ${errors.telefone ? "is-invalid" : ""}`}
                />
              )}
            </InputMask>
            {errors.telefone && (
              <FormFeedback>{errors.telefone}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="routing_number">Routing Number</Label>
            <InputMask
              mask="999999999"
              value={formData.routing_number || ""}
              onChange={handleInputChange}
            >
              {(inputProps) => (
                <Input
                  {...inputProps}
                  type="text"
                  name="routing_number"
                  id="routing_number"
                  placeholder="Número de ruta (9 dígitos)"
                  required
                  className={`form-control ${errors.routing_number ? "is-invalid" : ""}`}
                />
              )}
            </InputMask>
            {errors.routing_number && (
              <FormFeedback>{errors.routing_number}</FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="iban">IBAN</Label>
            <Input
              type="text"
              name="iban"
              id="iban"
              value={formData.iban || ""}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                handleInputChange(e);
              }}
              maxLength={34}
              placeholder="Número IBAN"
              required
              className={`form-control ${errors.iban ? "is-invalid" : ""}`}
            />
            {errors.iban && (
              <FormFeedback>{errors.iban}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="swift_bic">SWIFT/BIC</Label>
            <Input
              type="text"
              name="swift_bic"
              id="swift_bic"
              value={formData.swift_bic || ""}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                handleInputChange(e);
              }}
              maxLength={11}
              placeholder="Código SWIFT/BIC"
              required
              className={`form-control ${errors.swift_bic ? "is-invalid" : ""}`}
            />
            {errors.swift_bic && (
              <FormFeedback>{errors.swift_bic}</FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default UsdFields;
