import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";

const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [campo, setCampo] = useState("");
  const [value, setValue] = useState("");
  const [label, setLabel] = useState("");
  const [tipo, setTipo] = useState("string");
  const [auto, setAuto] = useState(false);
  const [editavelCliente, setEditavelCliente] = useState(false);

  const handleCampoChange = (e) => {
    // Remove any characters that aren't lowercase letters, numbers, or underscore
    const sanitizedValue = e.target.value.toLowerCase().replace(/[^a-z0-9_]/g, '');
    setCampo(sanitizedValue);
  };

  const toggle = () => {
    setCampo("");
    setValue("");
    setLabel("");
    setTipo("string");
    setAuto(false);
    setEditavelCliente(false);
    setModal(!modal);
  };

  const criar = async () => {
    if (!campo || !label) {
      toast.error("Por favor, complete los campos obligatorios");
      return;
    }

    // Additional validation for campo format
    if (!/^[a-z0-9_]+$/.test(campo)) {
      toast.error("Campo solo puede contener letras minúsculas, números y guión bajo");
      return;
    }

    setLoading(true);

    const data = {
      campo,
      value,
      label,
      tipo,
      auto,
      editavel_cliente: editavelCliente
    };

    const response = await apiClient.post(`/configs`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nueva Configuración
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="campo" className="form-label">
                  Campo *
                </Label>
                <Input
                  id="campo"
                  className="form-control"
                  type="text"
                  placeholder="Campo (solo letras minúsculas, números y _)"
                  value={campo}
                  onChange={handleCampoChange}
                  maxLength={50}
                />
                <small className="text-muted">
                  Solo letras minúsculas, números y guión bajo (_)
                </small>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="label" className="form-label">
                  Label *
                </Label>
                <Input
                  id="label"
                  className="form-control"
                  type="text"
                  placeholder="Label"
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  maxLength={50}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="tipo" className="form-label">
                  Tipo
                </Label>
                <Input
                  id="tipo"
                  type="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="string">String</option>
                  <option value="number">Number</option>
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="value" className="form-label">
                  Valor
                </Label>
                {tipo === 'number' ? (
                  <NumericFormat
                    className="form-control"
                    placeholder="0.00"
                    value={value}
                    onValueChange={({ value }) => setValue(value)}
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                  />
                ) : (
                  <Input
                    id="value"
                    className="form-control"
                    type="text"
                    placeholder="Valor"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="auto"
                  checked={auto}
                  onChange={(e) => setAuto(e.target.checked)}
                />
                <Label check for="auto">
                  Auto
                </Label>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="editavel_cliente"
                  checked={editavelCliente}
                  onChange={(e) => setEditavelCliente(e.target.checked)}
                />
                <Label check for="editavel_cliente">
                  Editable por Cliente
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-info fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-info"
              onClick={() => criar()}
              disabled={loading}
            >
              Crear
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
