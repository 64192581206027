import React from "react";
import { Modal, Button } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const DeleteCredentialModal = ({
  isOpen,
  toggle,
  selectedCredential,
  loading,
  onDeleteCredential,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className="modal-header">
        <h5 className="modal-title">Confirmar Eliminación</h5>
        <button type="button" className="btn-close" onClick={toggle}></button>
      </div>
      <div className="modal-body">
        <p>¿Está seguro de que desea eliminar esta credencial?</p>
        {selectedCredential?.pdv && (
          <p className="text-muted">
            <small>
              <FeatherIcon icon="link" size={14} className="me-1" />
              PDV: {selectedCredential.pdv.nome}
            </small>
          </p>
        )}
        <code className="d-block mb-3">{selectedCredential?.client_id}</code>
        <p className="text-danger mb-0">
          <FeatherIcon icon="alert-triangle" className="me-1" />
          Esta acción no se puede deshacer.
        </p>
      </div>
      <div className="modal-footer">
        <Link
          to="#"
          className="btn btn-link link-danger fw-medium"
          onClick={() => toggle()}
          disabled={loading}
        >
          <i className="ri-close-line me-1 align-middle"></i> Cerrar
        </Link>
        <Button color="danger" onClick={onDeleteCredential} disabled={loading}>
          {loading ? (
            <>
              <span className="spinner-border spinner-border-sm me-2" />
              Eliminando...
            </>
          ) : (
            "Eliminar"
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteCredentialModal;
