import React from "react";
import { Row, Col, Label, Input, FormGroup } from "reactstrap";
import { NumericFormat } from "react-number-format";
import { truncateDecimals } from "../../Components/FunctionsRepository";

const RetiradaConta = ({
  token,
  tokens,
  moeda,
  setMoeda,
  moedas,
  amount,
  setAmount,
  contaDestino,
  setContaDestino,
  contas,
  saldoUsdt,
  isUsdtAmount,
  setIsUsdtAmount,
}) => {
  // Quando a moeda muda, reseta a conta selecionada se ela não for compatível
  const handleMoedaChange = (value) => {
    setMoeda(value);
    const moedaSelecionada = moedas.find((m) => m.id === parseInt(value));
    if (moedaSelecionada) {
      const contaAtual = contas.find((c) => c.id === parseInt(contaDestino));
      if (contaAtual && contaAtual.pais !== moedaSelecionada.country_code) {
        setContaDestino("");
      }
    }
  };

  // Filtra as contas baseado na moeda selecionada
  const contasFiltradas = moeda
    ? contas.filter((conta) => {
        const moedaSelecionada = moedas.find((m) => m.id === parseInt(moeda));
        return moedaSelecionada && conta.pais === moedaSelecionada.country_code;
      })
    : [];

  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label>Token</Label>
          <Input type="select" value={token || ""} disabled={true}>
            <option value="">Seleccionar Token</option>
            {tokens.map((t) => (
              <option key={t.id} value={t.id}>
                {t.sigla} - ({t.rede.nome})
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Moneda</Label>
          <Input
            type="select"
            value={moeda || ""}
            onChange={(e) => handleMoedaChange(e.target.value)}
          >
            <option value="">Seleccionar Moneda</option>
            {moedas.map((m) => (
              <option key={m.id} value={m.id}>
                {m.sigla}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>
            Monto (
            {isUsdtAmount
              ? "USD"
              : moedas.find((m) => m.id === parseInt(moeda))?.sigla}
            )
          </Label>
          <div style={{ position: "relative" }}>
            <NumericFormat
              value={amount}
              onValueChange={(values) => setAmount(values.value)}
              className="form-control"
              placeholder="0.00"
              allowNegative={false}
              decimalScale={2}
              decimalSeparator="."
              thousandSeparator=","
            />
            {isUsdtAmount && (
              <button
                type="button"
                className="btn btn-sm btn-outline-info"
                style={{
                  position: "absolute",
                  right: "4px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "2px 8px",
                  fontSize: "12px",
                }}
                onClick={() =>
                  setAmount(truncateDecimals(parseFloat(saldoUsdt)).toString())
                }
              >
                MAX
              </button>
            )}
          </div>
          <div className="form-check mt-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="isUsdtAmount"
              checked={isUsdtAmount}
              onChange={() => {
                setIsUsdtAmount(!isUsdtAmount);
                setAmount("");
              }}
            />
            <label className="form-check-label" htmlFor="isUsdtAmount">
              Monto en USD?
            </label>
          </div>

          <small className="text-muted">
            <p className="mb-0">
              Wallet: ${truncateDecimals(parseFloat(saldoUsdt))}
            </p>
          </small>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Cuenta de Destino</Label>
          <Input
            type="select"
            value={contaDestino}
            onChange={(e) => setContaDestino(e.target.value)}
            disabled={!moeda}
          >
            <option value="">Seleccionar Cuenta</option>
            {contasFiltradas.map((c) => (
              <option key={c.id} value={c.id}>
                {c.banco} - {c.nome}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RetiradaConta;
