import React, { useEffect, useState, useCallback } from "react";
import {
  Row,
  Col,
  Label,
  Input,
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const UserConfigs = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [availableConfigs, setAvailableConfigs] = useState([]);
  const [newConfig, setNewConfig] = useState({
    campo: "",
    value: "",
  });

  const fetchConfigs = useCallback(async () => {
    const params = {
      user_id: userId,
    };
    const response = await apiClient.get("/configs/user", params);
    if (response.sucesso) {
      setConfigs(response.dados || []);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, [userId]);

  const fetchAvailableConfigs = useCallback(async () => {
    const params = {
      user_id: userId,
    };
    const response = await apiClient.get(`/configs/user/disponiveis`, params);
    if (response.sucesso) {
      // Extract just the campo property from each config object and filter out minimo_conversao_brl
      const availableFields = response.dados || [];
      setAvailableConfigs(availableFields);
      if (availableFields.length > 0) {
        setNewConfig((prev) => ({ ...prev, campo: availableFields[0].campo }));
      }
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const handleConfigChange = (index, value) => {
    const newConfigs = [...configs];
    newConfigs[index].value = value;
    setConfigs(newConfigs);
  };

  const handleNewConfigChange = (field, value) => {
    setNewConfig((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const addConfig = async () => {
    if (!newConfig.campo || !newConfig.value) {
      toast.error("Por favor, complete todos los campos");
      return;
    }

    setLoading(true);
    const updatedConfigs = [
      ...configs,
      { campo: newConfig.campo, value: newConfig.value },
    ];

    const data = {
      configs: updatedConfigs?.map((config) => ({
        campo: config.campo,
        value: config.value,
      })),
      user_id: userId,
    };

    const response = await apiClient.put(`/users/configs`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setNewConfig({ campo: availableConfigs[0]?.campo || "", value: "" });
      fetchConfigs();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const deleteConfig = async (campo, userId) => {
    setLoading(true);
    const response = await apiClient.delete("configs", {
      user_id: userId,
      campo,
    });
    if (response.sucesso) {
      toast.success(response.mensagem);
      await fetchConfigs();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const updateConfigs = async (configs, userId) => {
    setLoading(true);
    const data = {
      configs: configs?.map((config) => ({
        campo: config.campo,
        value: config.value,
      })),
      user_id: userId,
    };

    const response = await apiClient.put(`/users/configs`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      await fetchConfigs();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchAll = async () => {
      setLoading(true);
      await fetchConfigs();
      await fetchAvailableConfigs();
      setLoading(false);
    };
    if (userId) {
      fetchAll();
    }
  }, [userId]);

  if (!userId) return null;

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">
          Configuraciones del Usuario
        </h4>
      </CardHeader>

      <CardBody>
        <div>
          {loading ? (
            <div className="text-center">
              <Spinner size="sm" />
            </div>
          ) : (
            <>
              {availableConfigs.length > 0 && (
                <Row className="mb-4 border-bottom pb-3">
                  <Col md="4">
                    <Label htmlFor="config-campo" className="form-label">
                      Nueva Configuración
                    </Label>
                    <Input
                      id="config-campo"
                      type="select"
                      value={newConfig.campo}
                      onChange={(e) =>
                        handleNewConfigChange("campo", e.target.value)
                      }
                    >
                      {availableConfigs?.map((config) => (
                        <option key={config.campo} value={config.campo}>
                          {config.label || config.campo}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md="4">
                    <Label htmlFor="config-value" className="form-label">
                      Valor
                    </Label>
                    <Input
                      id="config-value"
                      type="text"
                      value={newConfig.value}
                      onChange={(e) =>
                        handleNewConfigChange("value", e.target.value)
                      }
                      placeholder="Ingrese el valor"
                    />
                  </Col>
                  <Col md="4">
                    <Label className="form-label">&nbsp;</Label>
                    <div>
                      <Button
                        color="primary"
                        onClick={addConfig}
                        disabled={loading}
                      >
                        Agregar Configuración
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}

              {configs.length > 0 ? (
                <>
                  <Row>
                    {configs?.map((config, index) => (
                      <Col md="4" key={config.campo} className="mb-3">
                        <div>
                          <Label
                            htmlFor={`config-${config.campo}`}
                            className="form-label d-flex justify-content-between align-items-center"
                          >
                            {config.label || config.campo}
                            <FeatherIcon
                              icon="x"
                              className="cursor-pointer text-danger"
                              size={16}
                              onClick={() => deleteConfig(config.campo, userId)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Label>
                          <Input
                            id={`config-${config.campo}`}
                            className="form-control"
                            type="text"
                            value={config.value || ""}
                            onChange={(e) =>
                              handleConfigChange(index, e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <div className="hstack gap-2 justify-content-end mt-3">
                    <Button
                      color="success"
                      onClick={() => updateConfigs(configs, userId)}
                      disabled={loading}
                    >
                      Actualizar Configuraciones
                    </Button>
                  </div>
                </>
              ) : (
                <div className="text-center text-muted">
                  No hay configuraciones disponibles
                </div>
              )}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default UserConfigs;
