import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalRecibo = ({ modal, setModal, setRefresh, retirada, userData }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [receiptUrl, setReceiptUrl] = useState("");

  const toggle = () => {
    setModal(!modal);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // Validate file type
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      toast.error(
        "Por favor, seleccione un archivo JPG, JPEG, PNG o PDF válido."
      );
      e.target.value = null;
    }
  };

  const enviarRecibo = async () => {
    setLoading(true);

    try {
      // Validate inputs based on converter flag
      if (retirada?.converter) {
        // File upload for converter = true
        if (!file) {
          toast.error("Por favor, seleccione un archivo de comprobante.");
          setLoading(false);
          return;
        }

        const formData = new FormData();
        formData.append("comprovante", file);
        formData.append("retirada_id", retirada.id);

        const extensaoComprovante = file.name.split(".").pop();
        formData.append("extensaoComprovante", extensaoComprovante);

        const response = await apiClient.put("/retiradas/recibo", formData);

        if (response.sucesso) {
          toast.success(response.mensagem);
          setRefresh(true);
          toggle();
        } else {
          toast.error(response.mensagem || "Error al subir el recibo");
        }
      } else {
        // URL input for converter = false
        if (!receiptUrl.trim()) {
          toast.error("Por favor, ingrese una URL de recibo válida.");
          setLoading(false);
          return;
        }

        const response = await apiClient.put("/retiradas/hash", {
          retirada_id: retirada.id,
          recibo_url: receiptUrl,
        });

        if (response.sucesso) {
          toast.success(response.mensagem);
          setRefresh(true);
          toggle();
        } else {
          toast.error(response.mensagem || "Error al enviar la URL del recibo");
        }
      }
    } catch (error) {
      console.error("Error al enviar recibo:", error);
      toast.error("Ocurrió un error al enviar el recibo");
    } finally {
      setLoading(false);
    }
  };

  // Only show modal for admin users
  if (userData?.role !== "admin") {
    return null;
  }

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader toggle={toggle}>
        {retirada?.converter ? "Subir Comprobante" : "Ingresar URL de Hash"}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Form>
          {retirada?.converter ? (
            <FormGroup>
              <Label>Comprobante (JPG, JPEG, PNG, PDF)</Label>
              <Input
                type="file"
                name="file"
                accept=".jpg,.jpeg,.png,.pdf"
                onChange={handleFileChange}
                disabled={loading}
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>URL del Hash</Label>
              <Input
                type="text"
                placeholder="Ingrese la URL del hash"
                value={receiptUrl}
                onChange={(e) => setReceiptUrl(e.target.value)}
                disabled={loading}
              />
            </FormGroup>
          )}

          <div className="hstack gap-2 justify-content-end mt-4">
            <Link
              to="#"
              className="btn btn-link link-danger fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <Button
              color="primary"
              onClick={() => enviarRecibo()}
              disabled={loading}
            >
              {loading ? "Enviando..." : "Enviar"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalRecibo;
