import React from "react";
import {
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import { truncateDecimals } from "../../Components/FunctionsRepository";

const RetiradaCripto = ({
  token,
  tokens,
  amount,
  setAmount,
  carteiraId,
  setCarteiraId,
  carteiras,
  saldoUsdt,
}) => {
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label>Token</Label>
          <Input
            type="select"
            value={token || ""}
            disabled={true}
            id="tokenSelect"
          >
            <option value="">Seleccionar Token</option>
            {tokens.map((t) => (
              <option key={t.id} value={t.id}>
                {t.sigla} - ({t.rede.nome})
              </option>
            ))}
          </Input>
          <UncontrolledTooltip placement="top" target="tokenSelect">
            Solo retiros en USDT por el momento
          </UncontrolledTooltip>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Monto (USD)</Label>
          <div style={{ position: "relative" }}>
            <NumericFormat
              value={amount}
              onValueChange={(values) => setAmount(values.value)}
              className="form-control"
              placeholder="Monto en USD"
              allowNegative={false}
              decimalScale={2}
              decimalSeparator="."
              thousandSeparator=","
            />
            <button
              type="button"
              className="btn btn-sm btn-outline-info"
              style={{
                position: "absolute",
                right: "4px",
                top: "50%",
                transform: "translateY(-50%)",
                padding: "2px 8px",
                fontSize: "12px",
              }}
              onClick={() =>
                setAmount(truncateDecimals(parseFloat(saldoUsdt)).toString())
              }
            >
              MAX
            </button>
          </div>
          <small className="text-muted">
            <p className="mb-0">
              Wallet: ${truncateDecimals(parseFloat(saldoUsdt))}
            </p>
            <p className="mb-0">Tasa de retiro: USD 1.00</p>
          </small>
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label>Wallet Cripto</Label>
          <Input
            type="select"
            value={carteiraId}
            onChange={(e) => setCarteiraId(e.target.value)}
          >
            <option value="">Seleccionar Wallet</option>
            {carteiras.map((c) => (
              <option key={c.id} value={c.id}>
                {c.nome} - {c.carteira}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RetiradaCripto;
