import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardBody, CardHeader, Input, Row, Col } from "reactstrap";
import { APIClient } from "../../../helpers/api_helper";
import ModalApagarConta from "./ModalApagarConta";
import ModalNovaConta from "./ModalNovaConta";
import ModalEditarConta from "./ModalEditarConta";
import FeatherIcon from "feather-icons-react";

const ContaClienteTable = ({ userId }) => {
  const [contas, setContas] = useState([]);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalNova, setModalNova] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [selectedConta, setSelectedConta] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [termoBusca, setTermoBusca] = useState("");
  const [itensPorPagina] = useState(10);
  const [paginaAtual, setPaginaAtual] = useState(1);

  const fetchContas = useCallback(async () => {
    const apiClient = new APIClient();
    const response = await apiClient.get("/contas-cliente", {
      user_id: userId,
    });
    if (response.sucesso) {
      setContas(response.dados);
    } else {
      console.error("Error al buscar cuentas:", response.mensagem);
    }
    setRefresh(false);
  }, [userId]);

  useEffect(() => {
    fetchContas();
  }, [fetchContas, refresh]);

  const handleDeleteClick = (conta) => {
    setSelectedConta(conta);
    setModalApagar(true);
  };

  const handleEditClick = (conta) => {
    setSelectedConta(conta);
    setModalEditar(true);
  };

  const handleNovaContaClick = () => {
    setModalNova(true);
  };

  // Filtrar contas baseado no termo de busca
  const contasFiltradas = contas.filter(conta => 
    conta.nome.toLowerCase().includes(termoBusca.toLowerCase()) ||
    conta.nome_destinatario.toLowerCase().includes(termoBusca.toLowerCase()) ||
    conta.banco.toLowerCase().includes(termoBusca.toLowerCase())
  );

  // Paginar contas
  const contasPaginadas = contasFiltradas.slice(
    (paginaAtual - 1) * itensPorPagina, 
    paginaAtual * itensPorPagina
  );

  const totalPaginas = Math.ceil(contasFiltradas.length / itensPorPagina);

  const handlePaginaAnterior = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const handleProximaPagina = () => {
    if (paginaAtual < totalPaginas) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Cuentas Bancarias</h4>
        <div className="flex-shrink-0">
          <Button color="soft-secondary" onClick={handleNovaContaClick}>
            Nueva Cuenta
          </Button>
        </div>
      </CardHeader>
      <CardBody className="p-3">
        <Row className="mb-4">
          <Col>
            <Input
              type="text"
              placeholder="Buscar cuentas..."
              value={termoBusca}
              onChange={(e) => {
                setTermoBusca(e.target.value);
                setPaginaAtual(1);
              }}
            />
          </Col>
        </Row>
        <div className="live-preview">
          <div className="table-responsive table-card">
            <table className="table align-middle table-nowrap table-striped-columns mb-1">
              <thead className="table-light">
                <tr>
                  <th>Nombre de la Cuenta</th>
                  <th>Destinatario</th>
                  <th>Banco</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {contasPaginadas.map((conta) => (
                  <tr key={conta.id}>
                    <td>{conta.nome}</td>
                    <td>{conta.nome_destinatario}</td>
                    <td>{conta.banco}</td>
                    <td>
                      <div className="hstack gap-2">
                        <Button
                          color="soft-primary"
                          size="sm"
                          className="me-1"
                          onClick={() => handleEditClick(conta)}
                        >
                          <FeatherIcon icon="edit-2" size={14} />
                        </Button>
                        <Button
                          color="soft-danger"
                          size="sm"
                          onClick={() => handleDeleteClick(conta)}
                        >
                          <FeatherIcon icon="trash-2" size={14} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {contasFiltradas.length > 0 && (
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="text-muted">
                Mostrando {Math.min((paginaAtual - 1) * itensPorPagina + 1, contasFiltradas.length)} 
                {" "}a{" "}
                {Math.min(paginaAtual * itensPorPagina, contasFiltradas.length)} 
                {" "}de {contasFiltradas.length} resultados
              </div>
              <div className="d-flex align-items-center">
                <Button 
                  color="soft-secondary" 
                  size="sm" 
                  className="me-2"
                  onClick={handlePaginaAnterior}
                  disabled={paginaAtual === 1}
                >
                  <FeatherIcon icon="chevron-left" size={16} />
                </Button>
                <span className="text-muted">
                  Página {paginaAtual} de {totalPaginas}
                </span>
                <Button 
                  color="soft-secondary" 
                  size="sm" 
                  className="ms-2"
                  onClick={handleProximaPagina}
                  disabled={paginaAtual === totalPaginas}
                >
                  <FeatherIcon icon="chevron-right" size={16} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </CardBody>
      <ModalApagarConta
        modal={modalApagar}
        setModal={setModalApagar}
        setRefresh={setRefresh}
        conta={selectedConta}
      />
      <ModalNovaConta
        modal={modalNova}
        setModal={setModalNova}
        userId={userId}
        setRefresh={setRefresh}
      />
      <ModalEditarConta
        modal={modalEditar}
        setModal={setModalEditar}
        conta={selectedConta}
        setRefresh={setRefresh}
      />
    </Card>
  );
};

export default ContaClienteTable;
