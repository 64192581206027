import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Badge,
  Spinner,
} from "reactstrap";
import Paginacao from "../../Components/Common/Paginacao";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

import SearchOption from "../../Components/Common/SearchOption";
import ModalApagar from "./ModalApagar";
import ModalNovo from "./ModalNovo";
import ModalEditar from "./ModalEditar";

import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";

const apiClient = new APIClient();

const LinksPagamento = () => {
  document.title = "Links de Pago | Nautt Finance";

  const [loading, setLoading] = useState(true);
  const [loadTable, setLoadTable] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [userDados, setUserDados] = useState(null);

  const [links, setLinks] = useState([]);
  const [link, setLink] = useState(null);
  const [itensPorPagina, setItensPorPagina] = useState(50);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeLinks, setTotalDeLinks] = useState(0);
  const [termoBusca, setTermoBusca] = useState("");

  const [modalEditar, setModalEditar] = useState(false);
  const [modalNovo, setModalNovo] = useState(false);
  const [modalApagar, setModalApagar] = useState(false);

  const toggleModalEditar = useCallback(
    (seg) => {
      setLink(seg);
      setModalEditar(!modalEditar);
    },
    [modalEditar]
  );

  const toggleModalNovo = useCallback(() => {
    setModalNovo(!modalNovo);
  }, [modalNovo]);

  const toggleModalApagar = useCallback(
    (seg) => {
      setLink(seg);
      setModalApagar(!modalApagar);
    },
    [modalApagar]
  );

  const fetchLinks = useCallback(
    async (itensPorPagina, paginaAtual, termoBusca) => {
      const data = {
        itens_pagina: itensPorPagina,
        pagina: paginaAtual,
        termo: termoBusca,
        order_by: "created_at",
        order: "desc",
      };

      const response = await apiClient.post("/links-pagamento/busca", data);
      if (response.sucesso) {
        const dados = response.dados;
        setLinks(dados.data);
        setTotalDeLinks(dados.total);
      } else {
        console.error(response.mensagem);
        if (!response.mensagem.includes(" 401"))
          if (response.mensagem !== "") toast.error(response.mensagem);
      }
      if (
        !response.sucesso &&
        (!response.responseCode || response.responseCode === 401)
      ) {
        await checkLastLogin();
      }
    },
    []
  );

  const changePage = (page) => {
    if (page !== paginaAtual) {  
      setPaginaAtual(page);
      setLoadTable(true);
      setRefresh(true);
    }
  };

  const refreshUser = useCallback(async () => {
    window.dispatchEvent(new Event("localDataUpdated"));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadTable(true);
        const userData = await getLoggedinUser();
        setUserDados(userData);
        await fetchLinks(itensPorPagina, paginaAtual, termoBusca);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      } finally {
        setLoadTable(false);
        setLoading(false);
        setRefresh(false);
      }
    };

    if (refresh || (links.length === 0 && loading)) {
      fetchData();
    }
  }, [refresh, itensPorPagina, paginaAtual, termoBusca, fetchLinks]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Registro de Links de Pago"
            pageTitle="Links de Pago"
          />
          <Card>
            <Row className="justify-content-between align-items-center mb-2 me-2 ms-2">
              <Col xl={3}>
                <label className="form-label">Ítems por página</label>
                <select
                  className="form-select mt-1"
                  aria-label=""
                  onChange={(e) => {
                    setItensPorPagina(parseInt(e.target.value));
                    setPaginaAtual(1);
                    setLoadTable(true);
                    setRefresh(true);
                  }}
                  value={itensPorPagina}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </Col>
              <Col xl={9} className="mb-0" style={{ marginTop: "32px" }}>
                <SearchOption
                  setTermoBusca={setTermoBusca}
                  setPaginaAtual={setPaginaAtual}
                  termoBusca={termoBusca}
                  setLoading={setRefresh}
                  setLoadTable={setLoadTable}
                />
              </Col>
            </Row>
          </Card>

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Links de Pago</h4>
                  <div className="flex-shrink-0">
                    <Link
                      to="#"
                      className="btn btn-soft-secondary"
                      onClick={() => {
                        toggleModalNovo();
                      }}
                    >
                      Nuevo Link de Pago
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  {loading || loadTable ? (
                    <Row className="justify-content-center mt-4 pt-2 text-center">
                      <Spinner />
                    </Row>
                  ) : (
                    <div className="live-preview">
                      <div className="table-responsive table-card">
                        <table className="table align-middle table-nowrap table-striped-columns mb-1">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">ID #</th>
                              <th scope="col">PDV</th>
                              <th scope="col">Descripción</th>
                              <th scope="col">Link</th>
                              <th scope="col">Estado</th>
                              <th scope="col">Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {links &&
                              links.length !== 0 &&
                              links.map((link, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{link.id}</td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      <p className="mb-0">
                                        <b>{link.pdv?.nome ?? "Ningún PDV"}</b>
                                      </p>
                                    </td>
                                    <td
                                      style={{
                                        overflowWrap: "break-word",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {/* apenas os 50 primeiros caracteres */}
                                      <p className="mb-0">
                                        {link.descricao?.substring(0, 50) || ""}
                                      </p>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/check-nautt/${link.link}`}
                                        className="btn btn-sm btn-success btn-label right ms-auto"
                                      >
                                        <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                                        Página del Link
                                      </Link>
                                      <Link
                                        to="#"
                                        className="btn btn-sm btn-link link-info fw-medium ms-2"
                                        title="Copiar Link"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            `https://painel.nautt.finance/check-nautt/${link.link}`
                                          );
                                          toast.success(
                                            "Link copiado al portapapeles"
                                          );
                                        }}
                                      >
                                        <FeatherIcon
                                          icon="copy"
                                          width="12"
                                          className="me-3"
                                        />
                                        Copiar Link
                                      </Link>
                                    </td>
                                    <td>
                                      {link?.ordem?.status === "pago" ? (
                                        <Badge color="success">Pagado</Badge>
                                      ) : link?.ordem?.status === "enviado" ? (
                                        <Badge color="success">Enviado</Badge>
                                      ) : link?.ordem?.status === "invalido" ? (
                                        <Badge color="danger">Invalido</Badge>
                                      ) : link?.ordem?.status === "expirada" ? (
                                        <Badge color="danger">Vencido</Badge>
                                      ) : link?.ordem?.status ===
                                        "cancelado" ? (
                                        <Badge color="dark">Cancelado</Badge>
                                      ) : link?.ordem?.status ===
                                        "processando" ? (
                                        <>
                                          <Badge color="success">Pagado</Badge>
                                          <Badge
                                            color="warning"
                                            className="ms-1"
                                          >
                                            Procesando
                                          </Badge>
                                        </>
                                      ) : link?.ordem?.status ===
                                        "finalizada" ? (
                                        <Badge color="secondary">
                                          Finalizado
                                        </Badge>
                                      ) : link?.ordem?.status ===
                                        "reembolsado" ? (
                                        <Badge color="primary">
                                          Reembolsado
                                        </Badge>
                                      ) : (
                                        <Badge color="info">Nuevo</Badge>
                                      )}
                                    </td>
                                    <td>
                                      {(userDados?.role === "admin" ||
                                        link?.ordem?.status === "novo") && (
                                        <>
                                          <Link
                                            to="#"
                                            className="text-success"
                                            title="Editar"
                                            onClick={() => {
                                              toggleModalEditar(link);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="edit"
                                              width="14"
                                              className="me-3"
                                            />
                                          </Link>
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            title="Borrar"
                                            onClick={() => {
                                              toggleModalApagar(link);
                                            }}
                                          >
                                            <FeatherIcon
                                              icon="trash-2"
                                              width="14"
                                            />
                                          </Link>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {!loading && !loadTable && links.length === 0 ? (
                    <Row>
                      <small className="text-muted text-center mt-5 mb-5">
                        Ningún link encontrado
                      </small>
                    </Row>
                  ) : (
                    <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
                      <div className="col-sm">
                        <div className="text-muted">
                          Mostrando{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              (paginaAtual - 1) * itensPorPagina + 1,
                              totalDeLinks
                            )}
                          </span>{" "}
                          a{" "}
                          <span className="fw-semibold">
                            {Math.min(
                              paginaAtual * itensPorPagina,
                              totalDeLinks
                            )}
                          </span>{" "}
                          de <span className="fw-semibold">{totalDeLinks}</span>{" "}
                          resultados
                        </div>
                      </div>
                      <div className="col-sm-auto mt-3 mt-sm-0">
                        <Paginacao
                          total={totalDeLinks}
                          currentPage={paginaAtual}
                          setCurrentPage={changePage}
                          perPageData={itensPorPagina}
                        />
                      </div>
                    </div>
                  )}
                  <ModalEditar
                    modal={modalEditar}
                    setModal={setModalEditar}
                    link={link}
                    setRefresh={setRefresh}
                  />
                  <ModalApagar
                    modal={modalApagar}
                    setModal={setModalApagar}
                    link={link}
                    setRefresh={setRefresh}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalNovo
            modal={modalNovo}
            setModal={setModalNovo}
            setRefresh={setRefresh}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default LinksPagamento;
