import React, { useState, Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";

const Informacoes = () => {
  document.title = "Informaciones | Nautt Finance";

  // State for each FAQ item
  const [borderCol1, setborderCol1] = useState(true);
  const [borderCol2, setborderCol2] = useState(false);
  const [borderCol3, setborderCol3] = useState(false);
  const [borderCol4, setborderCol4] = useState(false);
  const [borderCol5, setborderCol5] = useState(false);
  const [borderCol6, setborderCol6] = useState(false);
  const [borderCol7, setborderCol7] = useState(false);
  const [borderCol8, setborderCol8] = useState(false);
  const [borderCol9, setborderCol9] = useState(false);
  const [borderCol10, setborderCol10] = useState(false);
  const [borderCol11, setborderCol11] = useState(false);
  const [borderCol12, setborderCol12] = useState(false);
  const [borderCol13, setborderCol13] = useState(false);
  const [borderCol14, setborderCol14] = useState(false);
  const [borderCol15, setborderCol15] = useState(false);
  const [borderCol16, setborderCol16] = useState(false);

  // Toggle functions that close other items when one is opened
  const createToggleFunction = (currentSetState, otherSetStates) => {
    return () => {
      currentSetState((prev) => !prev);
      otherSetStates.forEach(setState => setState(false));
    };
  };

  const t_borderCol1 = createToggleFunction(setborderCol1, [
    setborderCol2, setborderCol3, setborderCol4, setborderCol5, 
    setborderCol6, setborderCol7, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol2 = createToggleFunction(setborderCol2, [
    setborderCol1, setborderCol3, setborderCol4, setborderCol5, 
    setborderCol6, setborderCol7, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol3 = createToggleFunction(setborderCol3, [
    setborderCol1, setborderCol2, setborderCol4, setborderCol5, 
    setborderCol6, setborderCol7, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol4 = createToggleFunction(setborderCol4, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol5, 
    setborderCol6, setborderCol7, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol5 = createToggleFunction(setborderCol5, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol6, setborderCol7, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol6 = createToggleFunction(setborderCol6, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol7, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol7 = createToggleFunction(setborderCol7, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol8, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol8 = createToggleFunction(setborderCol8, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol9, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol9 = createToggleFunction(setborderCol9, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol10, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol10 = createToggleFunction(setborderCol10, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol11, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol11 = createToggleFunction(setborderCol11, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol10, setborderCol12, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol12 = createToggleFunction(setborderCol12, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol10, setborderCol11, setborderCol13, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol13 = createToggleFunction(setborderCol13, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol10, setborderCol11, setborderCol12, 
    setborderCol14, setborderCol15, setborderCol16
  ]);

  const t_borderCol14 = createToggleFunction(setborderCol14, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol10, setborderCol11, setborderCol12, 
    setborderCol13, setborderCol15, setborderCol16
  ]);

  const t_borderCol15 = createToggleFunction(setborderCol15, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol10, setborderCol11, setborderCol12, 
    setborderCol13, setborderCol14, setborderCol16
  ]);

  const t_borderCol16 = createToggleFunction(setborderCol16, [
    setborderCol1, setborderCol2, setborderCol3, setborderCol4, 
    setborderCol5, setborderCol6, setborderCol7, setborderCol8, 
    setborderCol9, setborderCol10, setborderCol11, setborderCol12, 
    setborderCol13, setborderCol14, setborderCol15
  ]);

  return (
    <Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="FAQ" pageTitle="Informaciones" />
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Preguntas Frecuentes</h4>
                </CardHeader>
                <CardBody>
                  <div className="live-preview">
                    <Accordion
                      className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-info"
                      id="accordionBordered"
                      toggle={() => {}}
                      open=""
                    >
                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample1">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol1,
                            })}
                            type="button"
                            onClick={t_borderCol1}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo cambiar mi contraseña?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol1}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse1"
                        >
                          <div className="accordion-body">
                            Para cambiar tu contraseña, inicia sesión en tu cuenta, dirígete a tu perfil en la parte superior derecha de la pantalla y selecciona la opción "Cambiar contraseña". Sigue las instrucciones que aparecen en pantalla.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample2">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol2,
                            })}
                            type="button"
                            onClick={t_borderCol2}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo activar la autenticación de dos factores (2FA)?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol2}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse2"
                        >
                          <div className="accordion-body">
                            Accede a tu cuenta y dirígete a tu perfil en la parte superior derecha de la pantalla. Allí encontrarás la opción para activar el 2FA. Será necesario seguir las instrucciones, que implican escanear un código QR con una aplicación de autenticación (Google Authenticator).
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample3">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol3,
                            })}
                            type="button"
                            onClick={t_borderCol3}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Por qué es importante activar el 2FA?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol3}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse3"
                        >
                          <div className="accordion-body">
                            La autenticación de dos factores añade una capa adicional de seguridad a tu cuenta, protegiéndola contra accesos no autorizados. Aunque alguien tenga tu contraseña, necesitaría también el segundo factor (que solo tú tienes) para acceder a tu cuenta.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample4">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol4,
                            })}
                            type="button"
                            onClick={t_borderCol4}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo realizar retiros de moneda fiduciaria?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol4}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse4"
                        >
                          <div className="accordion-body">
                            Para realizar un retiro de moneda fiduciaria, ve a la sección de retiros en tu cuenta, selecciona "nuevo retiro", elige la moneda de retiro que prefieras, el monto y la cuenta destino, y sigue las indicaciones para completar la transacción.
                            <br /><br />
                            <strong>Tiempos de procesamiento:</strong> Los retiros solicitados de lunes a viernes en horario comercial se procesan en hasta 2 horas. Las solicitudes realizadas durante el fin de semana pueden tardar hasta 24 horas en completarse.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample5">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol5,
                            })}
                            type="button"
                            onClick={t_borderCol5}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo realizar un retiro de criptomonedas?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol5}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse5"
                        >
                          <div className="accordion-body">
                            Para realizar un retiro de criptomonedas, ve a la sección de retiros en tu cuenta, selecciona "nuevo retiro", luego en "retiro cripto" elige el monto y la cuenta destino, y sigue las indicaciones para completar la transacción.
                            <br /><br />
                            <strong>Tiempos de procesamiento:</strong> Los retiros solicitados de lunes a viernes en horario comercial se procesan en hasta 2 horas. Las solicitudes realizadas durante el fin de semana pueden tardar hasta 24 horas en completarse.
                            <br /><br />
                            <strong>Observación:</strong> Los retiros son realizados solamente a direcciones en RED ARBITRUM o RED POLYGON. Es importante realizar transferencias únicamente a cuentas en esas redes para evitar la pérdida de los fondos.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample6">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol6,
                            })}
                            type="button"
                            onClick={t_borderCol6}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo depositar dinero en mi cuenta?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol6}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse6"
                        >
                          <div className="accordion-body">
                            Para depositar dinero, inicia sesión y ve a la sección de "generar orden PIX". Elige tu moneda de pago preferida y sigue las instrucciones para completar la transacción.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample7">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol7,
                            })}
                            type="button"
                            onClick={t_borderCol7}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo hago un depósito en efectivo?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol7}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse7"
                        >
                          <div className="accordion-body">
                            Para realizar un depósito en efectivo, dirígete a la sección "Depósito", informa el monto y la moneda a depositar. Completa el formulario de depósito y dirígete al enlace de WhatsApp informado, donde se enviarán las instrucciones para la entrega del efectivo en una de nuestras sucursales.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample8">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol8,
                            })}
                            type="button"
                            onClick={t_borderCol8}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo hago una retirada en efectivo?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol8}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse8"
                        >
                          <div className="accordion-body">
                            Para realizar un retiro en efectivo, ve a la sección de retiros en tu cuenta, selecciona "nuevo retiro", luego en "retiro efectivo" elige el monto y la moneda a retirar, y dirígete al enlace de WhatsApp informado, donde se enviarán las instrucciones para la retirada del efectivo en una de nuestras sucursales.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample9">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol9,
                            })}
                            type="button"
                            onClick={t_borderCol9}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo crear un punto de venta?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol9}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse9"
                        >
                          <div className="accordion-body">
                            En la plataforma, dirígete a la sección de "Puntos de Venta" y selecciona "Crear nuevo punto de venta". Llena la información requerida y guarda los cambios para finalizar la creación.
                            <br /><br />
                            <strong>IMPORTANTE:</strong> El PIN configurado al momento de la creación del punto de venta será la clave utilizada para acceder.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample10">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol10,
                            })}
                            type="button"
                            onClick={t_borderCol10}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo crear un vendedor?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol10}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse10"
                        >
                          <div className="accordion-body">
                            Accede a la sección de gestión de vendedores y selecciona "Agregar nuevo vendedor". Proporciona la información solicitada y guarda los cambios.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample11">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol11,
                            })}
                            type="button"
                            onClick={t_borderCol11}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo generar un link de pago?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol11}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse11"
                        >
                          <div className="accordion-body">
                            Ve a la opción de "Links de Pago" en tu cuenta y selecciona "Crear nuevo link". Completa los detalles requeridos, como el monto y la descripción, y guarda para generar el link.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample12">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol12,
                            })}
                            type="button"
                            onClick={t_borderCol12}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo generar una orden de Pix?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol12}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse12"
                        >
                          <div className="accordion-body">
                            Para generar una orden de Pix, ingresa a la sección correspondiente en tu cuenta, elige "Crear orden de Pix", completa los detalles como el monto y el destinatario, y confirma la creación de la orden.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample13">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol13,
                            })}
                            type="button"
                            onClick={t_borderCol13}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo registrar un producto para posteriormente generar el link de pago?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol13}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse13"
                        >
                          <div className="accordion-body">
                            Dirígete a la sección de "Productos" y selecciona "Agregar nuevo producto". Completa la información del producto y guarda los cambios. Luego, ve a la sección de "Links de Pago", selecciona el producto registrado y sigue los pasos para generar el link de pago asociado.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample14">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol14,
                            })}
                            type="button"
                            onClick={t_borderCol14}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo añadir wallets de criptomonedas?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol14}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse14"
                        >
                          <div className="accordion-body">
                            Accede a tu cuenta y dirígete a tu perfil en la parte superior derecha de la pantalla. Allí encontrarás la opción para agregar las wallets de criptomonedas. En la sección "Wallets del Cliente", selecciona "nueva wallet" y sigue las instrucciones, que implican proporcionar la dirección de la wallet y la red correspondiente.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample15">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol15,
                            })}
                            type="button"
                            onClick={t_borderCol15}
                            style={{ cursor: "pointer" }}
                          >
                            ¿Cómo añadir cuentas bancarias?
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol15}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse15"
                        >
                          <div className="accordion-body">
                            Accede a tu cuenta y dirígete a tu perfil en la parte superior derecha de la pantalla. Allí encontrarás la opción para agregar las cuentas bancarias. En la sección "Cuentas Bancarias", selecciona "Nueva Cuenta" y sigue las instrucciones, que implican proporcionar los datos bancarios de la cuenta destino y de su titular.
                          </div>
                        </Collapse>
                      </AccordionItem>

                      <AccordionItem>
                        <h2 className="accordion-header" id="accordionborderedExample16">
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !borderCol16,
                            })}
                            type="button"
                            onClick={t_borderCol16}
                            style={{ cursor: "pointer" }}
                          >
                            Primeros Pasos en Nautt Finance: Tutorial Paso a Paso
                          </button>
                        </h2>
                        <Collapse
                          isOpen={borderCol16}
                          className="accordion-collapse"
                          id="accor_borderedExamplecollapse16"
                        >
                          <div className="accordion-body">
                            <iframe 
                              width="560" 
                              height="315" 
                              src="https://www.youtube.com/embed/k-FlN16KqLc?si=kT79a9igZojh_Z5p" 
                              title="YouTube video player" 
                              frameBorder="0" 
                              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                              referrerPolicy="strict-origin-when-cross-origin" 
                              allowFullScreen
                            ></iframe>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Informacoes;
