import React, { useState, useCallback, useEffect } from "react";
import InputMask from "react-input-mask";
import {
  Card,
  CardBody,
  Alert,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardText,
  Spinner,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import { Link } from "react-router-dom";

const apiClient = new APIClient();

const KoywePagamento = ({
  loading,
  ordem,
  resumoCalculo,
  formatCurrency,
  linkData,
  setOrdem,
  setLinkKoywe,
  linkKoywe,
}) => {
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [documentoDados, setDocumentoDados] = useState(null);
  const [metodosPagamento, setMetodosPagamento] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState("fisica");
  const [pais, setPais] = useState(null);
  const [moedaId, setMoedaId] = useState(null);
  const [documento, setDocumento] = useState("");
  const [email, setEmail] = useState(null);
  const [errors, setErrors] = useState({});
  const [metodoPagamentoSelecionado, setMetodoPagamentoSelecionado] =
    useState("");

  const getDocumentoPlaceholder = (mask) => {
    return mask?.replace(/9/g, "0");
  };

  const fetchMoedas = useCallback(async (resumoCalculo) => {
    setLoadingComponent(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;

      dados.forEach((moeda) => {
        if (moeda.sigla === resumoCalculo.moeda_sigla) {
          setDocumentoDados(moeda.tipo_documento);
          setPais(moeda.pais);
          setMoedaId(moeda.id);
        }
      });
    } else {
      console.error(response.mensagem);
      toast.error("Error al cargar las informaciones.");
    }
    setLoadingComponent(false);
  }, []);

  const fetchMetodosPagamento = useCallback(async (moedaId) => {
    setLoadingComponent(true);
    const response = await apiClient.get("/ordens-koywe/metodos-pagamento");
    if (response.sucesso) {
      const dados = response.dados;
      setMetodosPagamento(
        dados.filter((metodo) => metodo.moeda_id === parseInt(moedaId))
      );
    } else {
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoadingComponent(false);
  }, []);

  const handleVerificarClick = async () => {
    if (!documento) {
      setErrors({ documento: "Documento es obligatorio" });
      return;
    }

    setLoadingComponent(true);
    const response = await apiClient.post("/kyc/email/busca", { documento });
    if (response.sucesso) {
      setEmail(response.dados);
    } else {
      setEmail(response.dados || "erro_desconhecido");
    }
    setLoadingComponent(false);
  };

  const handleGerarLinkPagamento = async () => {
    if (!metodoPagamentoSelecionado) {
      toast.error("Seleccione un método de pago");
      return;
    }

    setLoadingComponent(true);
    const payload = {
      total: resumoCalculo.valor_final,
      moeda: resumoCalculo.moeda_sigla,
      email: email,
      documento: documento,
      metodo_pagamento: metodoPagamentoSelecionado,
      moeda_final: "USDT",
      pdv_id: linkData.pdv_id,
      link_id: linkData.id,
    };

    const response = await apiClient.post("/ordem-koywe", payload);
    if (response.sucesso && response.dados?.link_pagamento) {
      setLinkKoywe(response.dados.link_pagamento);
    } else {
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoadingComponent(false);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copiado al portapapeles");
    });
  };

  const handleCancelarOrdem = async () => {
    setLoadingComponent(true);
    const response = await apiClient.post("/ordem-koywe/cancelar", {
      link_id: linkData.id,
    });

    if (response.sucesso) {
      setOrdem(null);
      setMetodoPagamentoSelecionado("");
      setLinkKoywe(null);
    } else {
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoadingComponent(false);
  };

  useEffect(() => {
    const fetchAll = async () => {
      if (resumoCalculo) {
        await fetchMoedas(resumoCalculo);
        await fetchMetodosPagamento(moedaId);
      }
    };
    fetchAll();
  }, [resumoCalculo, fetchMoedas, moedaId]);

  const handleInputChange = (e) => {
    setDocumento(e.target.value);
    setErrors({});
  };

  const renderPaymentSection = () => {
    if (ordem) {
      if (ordem.status === "novo") {
        return (
          <div className="m-3">
            <Alert color="success">
              <div className="text-center">
                <p>¡Link de pago generado con éxito!</p>
                <p>Haga clic en el botón para finalizar el pago:</p>
                <Button
                  color="primary"
                  className="mt-2"
                  tag="a"
                  href={ordem.link_pagamento}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Finalizar Pago
                </Button>
                <div className="mt-2">
                  <Button
                    color="danger"
                    onClick={handleCancelarOrdem}
                    disabled={loadingComponent}
                  >
                    {loadingComponent ? (
                      <Spinner size="sm" />
                    ) : (
                      "Cambiar Metodo"
                    )}
                  </Button>
                </div>
              </div>
            </Alert>
          </div>
        );
      } else if (ordem.status === "em-andamento") {
        return (
          <div className="m-3">
            <Alert color="info">
              <div className="text-center">
                <p>El pago está siendo procesado.</p>
                <p>Por favor, aguarde la confirmación.</p>
              </div>
            </Alert>
          </div>
        );
      } else {
        return (
          <div className="m-3">
            <Alert color="warning">
              <div className="text-center">
                <p>Esta orden ya no está disponible para pago.</p>
              </div>
            </Alert>
          </div>
        );
      }
    }

    return (
      <CardBody>
        {!linkKoywe &&
          !(
            email &&
            typeof email === "string" &&
            email !== "nao_encontrado" &&
            email !== "em_andamento" &&
            email !== "negado" &&
            email !== "erro_desconhecido"
          ) && (
            <>
              <FormGroup>
                <Label for="tipoDocumento">Tipo de Documento</Label>
                <Input
                  type="select"
                  name="tipoDocumento"
                  id="tipoDocumento"
                  value={tipoDocumento}
                  disabled
                  className="form-select"
                >
                  <option value="fisica">Persona Física</option>
                  <option value="juridica">Persona Jurídica</option>
                </Input>
              </FormGroup>

              {documentoDados && (
                <FormGroup className="mt-3">
                  <Label for="documento">
                    {documentoDados.doc_pf} ({pais})
                  </Label>
                  <InputMask
                    mask={documentoDados.mask_pf}
                    value={documento}
                    onChange={handleInputChange}
                    className={`form-control ${
                      errors.documento ? "is-invalid" : ""
                    }`}
                    placeholder={getDocumentoPlaceholder(
                      documentoDados.mask_pf
                    )}
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        name="documento"
                        id="documento"
                      />
                    )}
                  </InputMask>
                </FormGroup>
              )}

              <div className="text-center mt-4">
                <Button
                  color="primary"
                  className="w-md"
                  onClick={() => handleVerificarClick()}
                  disabled={loadingComponent}
                >
                  {loadingComponent ? <Spinner size="sm" /> : "Verificar"}
                </Button>
              </div>
            </>
          )}

        {email &&
          typeof email === "string" &&
          email !== "nao_encontrado" &&
          email !== "em_andamento" &&
          email !== "negado" &&
          email !== "erro_desconhecido" &&
          !linkKoywe && (
            <div className="mt-4">
              <Alert color="success">
                <div className="text-center">
                  <p>Cliente encontrado!</p>
                  <p>Email: {email}</p>
                  <FormGroup className="mt-3">
                    <Label for="metodoPagamento">Método de Pago</Label>
                    <Input
                      type="select"
                      name="metodoPagamento"
                      id="metodoPagamento"
                      value={metodoPagamentoSelecionado}
                      onChange={(e) =>
                        setMetodoPagamentoSelecionado(e.target.value)
                      }
                      className="form-select"
                    >
                      <option value="">Seleccione un método</option>
                      {metodosPagamento.map((metodo) => (
                        <option key={metodo.koywe_id} value={metodo.koywe_id}>
                          {metodo.nome}
                        </option>
                      ))}
                      {resumoCalculo.moeda_sigla === "ARS" && (
                        <option value="bank_transfer">Bank Transfer</option>
                      )}
                    </Input>
                  </FormGroup>

                  {metodoPagamentoSelecionado === "bank_transfer" ? (
                    <div className="mt-3">
                      <Alert color="info">
                        <div className="text-center">
                          <p>
                            Asegúrese de transferir el monto exacto a la cuenta
                            informada y permanezca en esta pantalla para recibir
                            la confirmación.
                          </p>
                          <div className="d-flex align-items-center justify-content-center mt-3">
                            <div className="me-2">Alias: nautt.arg</div>
                            <Button
                              color="link"
                              className="p-0"
                              onClick={() => handleCopyToClipboard("nautt.arg")}
                            >
                              <FeatherIcon icon="copy" size={16} />
                            </Button>
                          </div>
                          <div className="d-flex align-items-center justify-content-center mt-2">
                            <div className="me-2">
                              Valor: ARS {resumoCalculo.valor_final}
                            </div>
                            <Button
                              color="link"
                              className="p-0"
                              onClick={() =>
                                handleCopyToClipboard(
                                  `${resumoCalculo.valor_final}`
                                )
                              }
                            >
                              <FeatherIcon icon="copy" size={16} />
                            </Button>
                          </div>
                        </div>
                      </Alert>
                    </div>
                  ) : (
                    <Button
                      color="primary"
                      className="mt-2"
                      onClick={handleGerarLinkPagamento}
                      disabled={loadingComponent || !metodoPagamentoSelecionado}
                    >
                      {loadingComponent ? (
                        <Spinner size="sm" />
                      ) : (
                        "Generar Link de Pago"
                      )}
                    </Button>
                  )}
                </div>
              </Alert>
            </div>
          )}

        {linkKoywe && (
          <div className="mt-4">
            <Alert color="success">
              <div className="text-center">
                <p>¡Link de pago generado con éxito!</p>
                <p>Haga clic en el botón para finalizar el pago:</p>
                <Button
                  color="primary"
                  className="mt-2"
                  tag="a"
                  href={linkKoywe}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Finalizar Pago
                </Button>
                <div className="mt-2">
                  <Button
                    color="danger"
                    onClick={handleCancelarOrdem}
                    disabled={loadingComponent}
                  >
                    {loadingComponent ? (
                      <Spinner size="sm" />
                    ) : (
                      "Cambiar Metodo"
                    )}
                  </Button>
                </div>
              </div>
            </Alert>
          </div>
        )}

        {email === "nao_encontrado" && (
          <div className="mt-4">
            <Alert color="warning">
              <div className="text-center">
                <p>Documento no encontrado en la base de datos.</p>
                <p>
                  Es necesario realizar el proceso KYC. Vuelva aquí después de
                  completar la verificación.
                </p>
                <Link to="/kyc-wizard">
                  <Button color="primary" className="mt-2">
                    Realizar KYC
                  </Button>
                </Link>
              </div>
            </Alert>
          </div>
        )}

        {email === "em_andamento" && (
          <div className="mt-4">
            <Alert color="warning">
              <div className="text-center">
                <p>Su verificación KYC está en proceso.</p>
                <p>
                  Por favor, espere hasta que se complete la verificación y
                  vuelva a intentarlo.
                </p>
              </div>
            </Alert>
          </div>
        )}

        {email === "negado" && (
          <div className="mt-4">
            <Alert color="danger">
              <div className="text-center">
                <p>Su verificación KYC fue rechazada.</p>
                <p>Por favor, realice el proceso nuevamente.</p>
                <Link to="/kyc-wizard">
                  <Button color="primary" className="mt-2">
                    Realizar KYC
                  </Button>
                </Link>
              </div>
            </Alert>
          </div>
        )}

        {email === "erro_desconhecido" && (
          <div className="mt-4">
            <Alert color="warning">
              <div className="text-center">
                <p>Ha ocurrido un error desconocido.</p>
                <p>Por favor, inténtelo nuevamente más tarde.</p>
              </div>
            </Alert>
          </div>
        )}
      </CardBody>
    );
  };

  return (
    <Col xl={4}>
      <div className="sticky-side-div">
        <Card>
          <CardHeader className="border-bottom-dashed">
            <h5 className="card-title mb-0">Finalizar Compra</h5>
          </CardHeader>
          <CardHeader className="bg-light-subtle border-bottom-dashed">
            <div className="text-center">
              <Alert color="info" className="mt-2">
                Por el momento, solo es posible realizar pagos como persona
                física
              </Alert>
            </div>
          </CardHeader>
          {renderPaymentSection()}
          <CardBody className="pt-2">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  {resumoCalculo && (
                    <>
                      {resumoCalculo.produtos.length > 0 ? (
                        <>
                          <tr>
                            <td>Subtotal :</td>
                            <td className="text-end">
                              {resumoCalculo.produtos.length > 0
                                ? formatCurrency(
                                    resumoCalculo.produtos.reduce(
                                      (total, produto, index) =>
                                        total +
                                        produto.preco_usd *
                                          linkData.produtos[index].pivot
                                            .quantidade *
                                          resumoCalculo.cotacao,
                                      0
                                    ),
                                    resumoCalculo.moeda_sigla
                                  )
                                : formatCurrency(
                                    resumoCalculo.valor_final - linkData.taxa,
                                    resumoCalculo.moeda_sigla
                                  )}
                            </td>
                          </tr>
                          <tr>
                            <td>Tasas :</td>
                            <td className="text-end">
                              {formatCurrency(
                                resumoCalculo.valor_final -
                                  resumoCalculo.produtos.reduce(
                                    (total, produto, index) =>
                                      total +
                                      produto.preco_usd *
                                        linkData.produtos[index].pivot
                                          .quantidade *
                                        resumoCalculo.cotacao,
                                    0
                                  ),
                                resumoCalculo.moeda_sigla
                              )}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      <tr className="border-top border-top-dashed">
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="fw-semibold">
                        <td>Total :</td>
                        <td className="text-end">
                          {formatCurrency(
                            resumoCalculo.valor_final,
                            resumoCalculo.moeda_sigla
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default KoywePagamento;
