import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  Button,
  CardText,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";

const apiClient = new APIClient();

const UsdPagamento = ({
  loading,
  countdown,
  ordem,
  resumoCalculo,
  formatCurrency,
  linkData,
  setOrdem,
  setCountdown,
  startCountdown,
}) => {
  const renderPaymentSection = () => {
    if (ordem && ordem.status !== "novo") {
      return (
        <CardBody>
          <div
            className={`rounded-circle p-3 mb-2 ${
              ordem.status === "pago" ? "bg-success" : "bg-danger"
            }`}
            style={{ width: "100px", height: "100px", margin: "0 auto" }}
          >
            <FeatherIcon
              icon={ordem.status === "pago" ? "check-circle" : "x-circle"}
              className="text-white"
              size={70}
            />
          </div>
          <CardText
            tag="h4"
            className={`font-weight-bold mt-4 text-${
              ordem.status === "pago" ? "success" : "danger"
            }`}
          >
            {ordem.status === "pago" ? "Pago Recibido" : "Orden Inválida"}
          </CardText>
          <CardText>
            {ordem.status === "pago"
              ? "El pago se ha procesado con éxito."
              : "La orden se consideró inválida. Por favor, inténtelo de nuevo."}
          </CardText>
        </CardBody>
      );
    }

    return (
      <CardBody>
        {countdown > 0 ? (
          <>
            <div className="text-center mt-3">
              <h5>
                Tiempo restante: {Math.floor(countdown / 60)}:
                {countdown % 60 < 30 ? "0" : ""}
                {countdown % 60}
              </h5>
            </div>
          </>
        ) : null}
      </CardBody>
    );
  };

  return (
    <Col xl={4}>
      <div className="sticky-side-div">
        <Card>
          <CardHeader className="border-bottom-dashed">
            <h5 className="card-title mb-0">Finalizar Compra</h5>
          </CardHeader>
          <CardHeader className="bg-light-subtle border-bottom-dashed">
            <div className="text-center">
              {countdown === 0 ? (
                <>
                  <Button
                    color="primary"
                    className="w-xs mb-3"
                    disabled={true}
                  >
                    {loading ? <Spinner size="sm" /> : "Generar Ordem"}
                  </Button>
                  <UncontrolledAlert color="warning" className="mb-0">
                    <div className="d-flex align-items-center">
                      <FeatherIcon icon="alert-triangle" size={18} className="me-2" />
                      <div>
                        Lo sentimos, actualmente no hay métodos de pago disponibles para dólares.
                      </div>
                    </div>
                  </UncontrolledAlert>
                </>
              ) : null}
            </div>
          </CardHeader>
          {renderPaymentSection()}
          <CardBody className="pt-2">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  {resumoCalculo && (
                    <>
                      {resumoCalculo.produtos.length > 0 ? (
                        <>
                          <tr>
                            <td>Subtotal :</td>
                            <td className="text-end">
                              {resumoCalculo.produtos.length > 0
                                ? formatCurrency(
                                    resumoCalculo.produtos.reduce(
                                      (total, produto, index) =>
                                        total +
                                        produto.preco_usd *
                                          linkData.produtos[index].pivot
                                            .quantidade *
                                          resumoCalculo.cotacao,
                                      0
                                    ),
                                    resumoCalculo.moeda_sigla
                                  )
                                : formatCurrency(
                                    resumoCalculo.valor_final - linkData.taxa,
                                    resumoCalculo.moeda_sigla
                                  )}
                            </td>
                          </tr>
                          <tr>
                            <td>Tasas :</td>
                            <td className="text-end">
                              {formatCurrency(
                                resumoCalculo.valor_final -
                                  resumoCalculo.produtos.reduce(
                                    (total, produto, index) =>
                                      total +
                                      produto.preco_usd *
                                        linkData.produtos[index].pivot
                                          .quantidade *
                                        resumoCalculo.cotacao,
                                    0
                                  ),
                                resumoCalculo.moeda_sigla
                              )}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      <tr className="border-top border-top-dashed">
                        <td colSpan="2"></td>
                      </tr>
                      <tr className="fw-semibold">
                        <td>Total :</td>
                        <td className="text-end">
                          {formatCurrency(
                            resumoCalculo.valor_final,
                            resumoCalculo.moeda_sigla
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default UsdPagamento;
