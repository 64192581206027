import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  Table,
  Spinner,
  Badge,
} from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
  formatCurrencyUSD,
  converterData,
} from "../../Components/FunctionsRepository";
import GreatPagination from "../../Components/Common/GreatPagination";

const apiClient = new APIClient();

const RelatorioOrdens = ({ pdv_id, atendente_id, pin }) => {
  const [ordens, setOrdens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(0);

  const fetchOrdens = useCallback(
    async (page, pinPdv) => {
      setLoading(true);
      try {
        const data = {
          pin: pinPdv,
          pdv_id: pdv_id,
          atendente_id: atendente_id,
          pagina: page,
          itens_pagina: 10,
          order_by: "created_at",
          order: "desc",
        };

        const response = await apiClient.post("/ordens-pix/busca", data);
        if (response.sucesso) {
          const dados = response.dados;
          setOrdens(dados.data);
          setTotalPaginas(dados.last_page);
        } else {
          console.error(response.mensagem);
          if (!response.mensagem.includes(" 401"))
            if (response.mensagem !== "") toast.error(response.mensagem);
        }
        if (
          !response.sucesso &&
          (!response.responseCode || response.responseCode === 401)
        ) {
          await checkLastLogin();
        }
      } catch (error) {
        toast.error("Error al buscar informe");
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [pdv_id, atendente_id, pin]
  );

  useEffect(() => {
    const fetchData = async (newPage, pinPdv) => {
      await fetchOrdens(newPage, pinPdv);
    };
    fetchData(pagina, pin);
  }, [pagina, pin, fetchOrdens]);

  const handlePageChange = async (newPage) => {
    if (newPage > 0 && newPage <= totalPaginas) {
      setPagina(newPage);
      await fetchOrdens(newPage, pin);
    }
  };

  return (
    <div className="w-100">
      <Card>
        <CardBody>
          <h4 className="card-title mb-4 text-center">Informe de Órdenes</h4>

          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : ordens.length === 0 ? (
            <div className="text-center">
              <p>Ninguna orden encontrada</p>
            </div>
          ) : (
            <>
              <Table responsive striped hover>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Descripción</th>
                    <th>Total BRL</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {ordens.map((ordem) => (
                    <tr key={ordem.id}>
                      <td>{converterData(ordem.created_at)}</td>
                      <td
                        style={{
                          overflowWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: ordem.descricao,
                          }}
                        />
                      </td>
                      <td>{formatCurrency(ordem.total_brl)}</td>
                      <td>
                        {ordem.status === "pago" ? (
                          <Badge color="success">Pagado</Badge>
                        ) : ordem.status === "enviado" ? (
                          <Badge color="success">Enviado</Badge>
                        ) : ordem.status === "invalido" ? (
                          <Badge color="danger">Invalido</Badge>
                        ) : ordem.status === "expirada" ? (
                          <Badge color="danger">Vencido</Badge>
                        ) : ordem.status === "cancelado" ? (
                          <Badge color="dark">Cancelado</Badge>
                        ) : ordem.status === "processando" ? (
                          <>
                            <Badge color="success">Pagado</Badge>
                            <Badge color="warning" className="ms-1">
                              Procesando
                            </Badge>
                          </>
                        ) : ordem.status === "finalizada" ? (
                          <Badge color="secondary">Finalizado</Badge>
                        ) : ordem.status === "reembolsado" ? (
                          <Badge color="primary">Reembolsado</Badge>
                        ) : (
                          <Badge color="info">Nuevo</Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <GreatPagination
                currentPage={pagina}
                totalPages={totalPaginas}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default RelatorioOrdens;
