import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, retirada, setRefresh, userDados }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [cotacao, setCotacao] = useState("");
  const [amountFinal, setAmountFinal] = useState("");
  const [lucroNautt, setLucroNautt] = useState("");

  const toggle = () => {
    setStatus("");
    setCotacao("");
    setAmountFinal("");
    setLucroNautt("");
    setModal(!modal);
  };

  const isReadOnly = ["finalizada", "cancelada"].includes(retirada?.status);

  const copyToClipboard = (text, fieldName) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success(`${fieldName} copiado al portapapeles`);
    });
  };

  const AccountField = ({ label, value, showCopy = true }) => {
    if (!value) return null;

    return (
      <Col md={6}>
        <div className="d-flex align-items-center mb-2">
          <div className="flex-grow-1">
            <small className="text-muted">{label}</small>
            <div className="fw-medium">{value}</div>
          </div>
          {showCopy && (
            <div className="ms-2">
              <FeatherIcon
                icon="copy"
                size={16}
                className="cursor-pointer text-muted"
                onClick={() => copyToClipboard(value, label)}
                style={{ cursor: "pointer" }}
              />
            </div>
          )}
        </div>
      </Col>
    );
  };

  const editar = async () => {
    if (isReadOnly) return;

    setLoading(true);

    const data = {
      status: status,
      retirada_id: retirada.id,
    };

    if (retirada?.converter) {
      data.cotacao = parseFloat(cotacao);
      data.amount_final = parseFloat(amountFinal);
      data.lucro_nautt = parseFloat(lucroNautt);
    }

    const response = await apiClient.put(`/retiradas`, data);

    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDados = async () => {
      if (retirada?.converter) {
        setCotacao(retirada.cotacao);
        setAmountFinal(retirada.amount_final);
        setLucroNautt(retirada.lucro_nautt);
        setStatus(retirada.status);
      }
    };

    fetchDados();
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {isReadOnly ? "Ver Retiro" : "Editar Retiro"}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Token</Label>
              <Input
                type="text"
                value={
                  `${retirada?.token?.sigla} (${retirada?.token?.rede?.nome})` ||
                  ""
                }
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Monto Original (USD)</Label>
              <NumericFormat
                value={
                  userDados?.role === "admin"
                    ? retirada?.amount 
                    : (parseFloat(retirada?.amount || "0") +
                    parseFloat(retirada?.lucro_nautt || "0")).toFixed(2)
                }
                className="form-control"
                disabled
                allowNegative={false}
                decimalScale={2}
                decimalSeparator="."
                thousandSeparator=","
              />
            </FormGroup>
          </Col>

          {retirada?.converter ? (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Cotización</Label>
                  <NumericFormat
                    value={cotacao}
                    onValueChange={(values) => setCotacao(values.value)}
                    className="form-control"
                    disabled={isReadOnly}
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Por Cobrar</Label>
                  <NumericFormat
                    value={amountFinal}
                    onValueChange={(values) => setAmountFinal(values.value)}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              {userDados?.role === "admin" && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Tasa (USD)</Label>
                    <NumericFormat
                      value={lucroNautt}
                      onValueChange={(values) => setLucroNautt(values.value)}
                      className="form-control"
                      disabled={isReadOnly}
                      allowNegative={false}
                      decimalScale={2}
                      decimalSeparator="."
                      thousandSeparator=","
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label>Moneda</Label>
                  <Input
                    type="text"
                    value={`${retirada?.moeda?.nome} (${retirada?.moeda?.sigla}) - ${retirada?.moeda?.pais}`}
                    disabled
                  />
                </FormGroup>
              </Col>
            </>
          ) : (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Por Cobrar (USD)</Label>
                  <NumericFormat
                    value={retirada?.amount_final || ""}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              {userDados?.role === "admin" && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Tasa (USD)</Label>
                    <NumericFormat
                      value={retirada?.lucro_nautt || ""}
                      className="form-control"
                      disabled
                      allowNegative={false}
                      decimalScale={2}
                      decimalSeparator="."
                      thousandSeparator=","
                    />
                  </FormGroup>
                </Col>
              )}
            </>
          )}
          <Col md={userDados?.role === "admin" ? 12 : 6}>
            <FormGroup>
              <Label>Estado</Label>
              <Input
                type="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                disabled={isReadOnly}
              >
                <option value="">Seleccionar Estado</option>
                <option value="nova">Nueva</option>
                <option value="processando">Procesando</option>
                <option value="finalizada">Finalizada</option>
                <option value="cancelada">Cancelada</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          {retirada?.converter ? (
            <Col md={12}>
              <FormGroup>
                <Label>Cuenta de Destino</Label>
                <Card className="mb-0">
                  <CardBody className="p-4">
                    <Row>
                      <AccountField
                        label="Nombre de la Cuenta"
                        value={retirada?.conta?.nome}
                        showCopy={false}
                      />
                      <AccountField
                        label="País"
                        value={retirada?.conta?.pais}
                        showCopy={false}
                      />
                      <AccountField
                        label="Tipo"
                        value={
                          retirada?.conta?.tipo === "fisica"
                            ? "Física"
                            : "Jurídica"
                        }
                        showCopy={false}
                      />
                      <AccountField
                        label="Banco"
                        value={retirada?.conta?.banco}
                      />
                      <AccountField
                        label="Agencia"
                        value={retirada?.conta?.agencia}
                      />
                      <AccountField
                        label="Cuenta"
                        value={retirada?.conta?.conta}
                      />
                      <AccountField
                        label="PIX"
                        value={retirada?.conta?.pix_alias}
                      />
                      <AccountField
                        label="Nombre del Destinatario"
                        value={retirada?.conta?.nome_destinatario}
                      />
                      <AccountField
                        label="Documento"
                        value={retirada?.conta?.documento}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </FormGroup>
            </Col>
          ) : (
            <Col md={12}>
              <FormGroup>
                <Label>Wallet Cripto</Label>
                <Input
                  type="text"
                  value={retirada?.carteira?.carteira || ""}
                  disabled
                />
              </FormGroup>
            </Col>
          )}
        </Row>

        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-primary fw-medium"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Cerrar
          </Link>
          {!isReadOnly && (
            <button
              className="btn btn-primary"
              onClick={() => editar()}
              disabled={loading}
            >
              Guardar
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
