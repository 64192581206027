import React, { useState, useEffect, useCallback } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Row,
  Col,
} from "reactstrap";
import { APIClient } from "../../../helpers/api_helper";
import ModalApagarCarteira from "./ModalApagarCarteira";
import ModalNovaCarteira from "./ModalNovaCarteira";
import ModalEditarCarteira from "./ModalEditarCarteira";
import FeatherIcon from "feather-icons-react";

const CarteiraClienteTable = ({ userId }) => {
  const [carteiras, setCarteiras] = useState([]);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalNova, setModalNova] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [selectedCarteira, setSelectedCarteira] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [termoBusca, setTermoBusca] = useState("");
  const [itensPorPagina] = useState(10);
  const [paginaAtual, setPaginaAtual] = useState(1);

  const fetchCarteiras = useCallback(async () => {
    const apiClient = new APIClient();
    const response = await apiClient.get("/carteira-cliente", {
      user_id: userId,
    });
    if (response.sucesso) {
      setCarteiras(response.dados);
    } else {
      console.error("Error al buscar wallets:", response.mensagem);
    }
    setRefresh(false);
  }, [userId]);

  useEffect(() => {
    fetchCarteiras();
  }, [fetchCarteiras, refresh]);

  const getBadgeColor = (tipo) => {
    return tipo.toLowerCase() === "comum" ? "primary" : "success";
  };

  const handleDeleteClick = (carteira) => {
    setSelectedCarteira(carteira);
    setModalApagar(true);
  };

  const handleEditClick = (carteira) => {
    setSelectedCarteira(carteira);
    setModalEditar(true);
  };

  const handleNovaCarteiraClick = () => {
    setModalNova(true);
  };

  // Filtrar carteiras baseado no termo de busca
  const carteirasFiltradas = carteiras.filter(
    (carteira) =>
      carteira.nome.toLowerCase().includes(termoBusca.toLowerCase()) ||
      carteira.rede.nome.toLowerCase().includes(termoBusca.toLowerCase())
  );

  // Paginar carteiras
  const carteirasPaginadas = carteirasFiltradas.slice(
    (paginaAtual - 1) * itensPorPagina,
    paginaAtual * itensPorPagina
  );

  const totalPaginas = Math.ceil(carteirasFiltradas.length / itensPorPagina);

  const handlePaginaAnterior = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const handleProximaPagina = () => {
    if (paginaAtual < totalPaginas) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Wallets del cliente</h4>
        <div className="flex-shrink-0">
          <Button color="soft-secondary" onClick={handleNovaCarteiraClick}>
            Nueva Wallet
          </Button>
        </div>
      </CardHeader>
      <CardBody className="p-3">
        <Row className="mb-4">
          <Col>
            <Input
              type="text"
              placeholder="Buscar wallets..."
              value={termoBusca}
              onChange={(e) => {
                setTermoBusca(e.target.value);
                setPaginaAtual(1);
              }}
            />
          </Col>
        </Row>
        <div className="live-preview">
          <div className="table-responsive table-card">
            <table className="table align-middle table-nowrap table-striped-columns mb-1">
              <thead className="table-light">
                <tr>
                  <th>Nombre</th>
                  <th>Red</th>
                  <th>Tipo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {carteirasPaginadas.map((carteira) => (
                  <tr key={carteira.id}>
                    <td>{carteira.nome}</td>
                    <td>{carteira.rede.nome}</td>
                    <td>
                      <Badge color={getBadgeColor(carteira.tipo)}>
                        {carteira.tipo.charAt(0).toUpperCase() +
                          carteira.tipo.slice(1)}
                      </Badge>
                    </td>
                    <td>
                      <div className="hstack gap-2">
                        <Button
                          color="soft-primary"
                          size="sm"
                          className="me-1"
                          onClick={() => handleEditClick(carteira)}
                        >
                          <FeatherIcon icon="edit-2" size={14} />
                        </Button>
                        <Button
                          color="soft-danger"
                          size="sm"
                          onClick={() => handleDeleteClick(carteira)}
                        >
                          <FeatherIcon icon="trash-2" size={14} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {carteirasFiltradas.length > 0 && (
            <div className="d-flex justify-content-between align-items-center mt-4">
              <div className="text-muted">
                Mostrando{" "}
                {Math.min(
                  (paginaAtual - 1) * itensPorPagina + 1,
                  carteirasFiltradas.length
                )}{" "}
                a{" "}
                {Math.min(
                  paginaAtual * itensPorPagina,
                  carteirasFiltradas.length
                )}{" "}
                de {carteirasFiltradas.length} resultados
              </div>
              <div className="d-flex align-items-center">
                <Button
                  color="soft-secondary"
                  size="sm"
                  className="me-2"
                  onClick={handlePaginaAnterior}
                  disabled={paginaAtual === 1}
                >
                  <FeatherIcon icon="chevron-left" size={16} />
                </Button>
                <span className="text-muted">
                  Página {paginaAtual} de {totalPaginas}
                </span>
                <Button
                  color="soft-secondary"
                  size="sm"
                  className="ms-2"
                  onClick={handleProximaPagina}
                  disabled={paginaAtual === totalPaginas}
                >
                  <FeatherIcon icon="chevron-right" size={16} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </CardBody>
      <ModalApagarCarteira
        modal={modalApagar}
        setModal={setModalApagar}
        setRefresh={setRefresh}
        carteira={selectedCarteira}
      />
      <ModalNovaCarteira
        modal={modalNova}
        setModal={setModalNova}
        userId={userId}
        setRefresh={setRefresh}
      />
      <ModalEditarCarteira
        modal={modalEditar}
        setModal={setModalEditar}
        carteira={selectedCarteira}
        setRefresh={setRefresh}
      />
    </Card>
  );
};

export default CarteiraClienteTable;
