import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";

const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, config }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [label, setLabel] = useState("");
  const [tipo, setTipo] = useState("string");
  const [editavelCliente, setEditavelCliente] = useState(false);

  useEffect(() => {
    if (modal && config) {
      setValue(config.value || "");
      setLabel(config.label || "");
      setTipo(config.tipo || "string");
      setEditavelCliente(config.editavel_cliente || false);
    }
  }, [modal, config]);

  const toggle = () => {
    setValue("");
    setLabel("");
    setTipo("string");
    setEditavelCliente(false);
    setModal(!modal);
  };

  const editar = async () => {
    if (!label) {
      toast.error("Por favor, complete los campos obligatorios");
      return;
    }

    setLoading(true);

    const data = {
      config_id: config.id,
      value: config.auto ? config.value : value,
      label,
      tipo,
      editavel_cliente: editavelCliente,
    };

    const response = await apiClient.put(`/configs`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Configuración
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="label" className="form-label">
                  Label 
                </Label>
                <Input
                  id="label"
                  className="form-control"
                  type="text"
                  placeholder="Label"
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  maxLength={50}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="campo" className="form-label">
                  Campo
                </Label>
                <Input
                  id="campo"
                  className="form-control"
                  type="text"
                  value={config?.campo || ""}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="tipo" className="form-label">
                  Tipo
                </Label>
                <Input
                  id="tipo"
                  type="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  disabled
                >
                  <option value="string">String</option>
                  <option value="number">Number</option>
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="value" className="form-label">
                  Valor
                </Label>
                {tipo === "number" ? (
                  <NumericFormat
                    className="form-control"
                    placeholder="0.00"
                    value={value}
                    onValueChange={({ value }) => setValue(value)}
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    disabled={config?.auto}
                  />
                ) : (
                  <Input
                    id="value"
                    className="form-control"
                    type="text"
                    placeholder="Valor"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    disabled={config?.auto}
                  />
                )}
                {config?.auto ? (
                  <small className="text-muted">
                    Este valor es automático y no puede ser editado
                  </small>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="6">
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="editavel_cliente"
                  checked={editavelCliente}
                  onChange={(e) => setEditavelCliente(e.target.checked)}
                />
                <Label check for="editavel_cliente">
                  Editable por Cliente
                </Label>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup check>
                <Input
                  type="checkbox"
                  id="auto"
                  checked={config?.auto || false}
                  disabled
                />
                <Label check for="auto">
                  Auto
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-success"
              onClick={() => editar()}
              disabled={loading}
            >
              Guardar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
