import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const GreatPagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPaginationItems = () => {
    const delta = 2; // Number of pages to show before and after current page
    const range = [];
    const rangeWithDots = [];

    // Always add page 1
    range.push(1);

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
      if (i > 1 && i < totalPages) {
        range.push(i);
      }
    }

    // Always add last page
    if (totalPages > 1) {
      range.push(totalPages);
    }

    let l;
    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots.map((item, index) => {
      if (item === '...') {
        return (
          <PaginationItem key={`ellipsis-${index}`} disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
      }
      return (
        <PaginationItem key={item} active={item === currentPage}>
          <PaginationLink onClick={() => onPageChange(item)}>
            {item}
          </PaginationLink>
        </PaginationItem>
      );
    });
  };

  return (
    <div className="d-flex justify-content-center">
      <Pagination>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink onClick={() => onPageChange(currentPage - 1)}>
            <FeatherIcon icon="chevron-left" size={18} />
          </PaginationLink>
        </PaginationItem>

        {renderPaginationItems()}

        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink onClick={() => onPageChange(currentPage + 1)}>
            <FeatherIcon icon="chevron-right" size={18} />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default GreatPagination;
