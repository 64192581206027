import React, { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Button, Row } from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import Section from "./Section";
import ModalSaque from "./ModalSaque";
import { truncateDecimals } from "../../Components/FunctionsRepository";
const apiClient = new APIClient();

const Widgets = ({userData}) => {
  const [ecomWidgets, setEcomWidgets] = useState([]);
  const [modal, setModal] = useState(false);
  const [fundosUSD, setFundosUSD] = useState(0);
  const [nauttPontos, setNauttPontos] = useState(0);

  const toggleModal = () => setModal(!modal);

  const getWidgetsData = useCallback(async () => {
    const response = await apiClient.get("/widgets", {});
    if (response.sucesso) {
      const dados = response.dados;
      console.log(dados);
      let widgets = [
        {
          id: 1,
          label: "Dólar / Real Brasileño",
          counter: "0",
          counter2: "0",
          bgcolor: "success",
          icon: "bx bx-dollar-circle",
          decimals: 2,
          prefix: "R$ ",
        },
        {
          id: 2,
          label: "Dólar / Peso Argentino",
          counter: "0",
          counter2: "0",
          bgcolor: "secondary",
          icon: "bx bx-dollar-circle",
          decimals: 2,
          suffix: " ARS",
        },
        {
          id: 3,
          label: "Peso Chileno / Dólar",
          counter: "0",
          counter2: "0",
          bgcolor: "secondary",
          icon: "bx bx-dollar-circle",
          decimals: 2,
          suffix: " CLP",
        },
      ];
      widgets[0].counter = dados.brl_usd.compra;
      widgets[0].counter2 = dados.brl_usd.venda;
      widgets[1].counter = dados.ars_usd.compra;
      widgets[1].counter2 = dados.ars_usd.venda;
      widgets[2].counter = dados.clp_usd.compra;
      widgets[2].counter2 = dados.clp_usd.venda;
      setFundosUSD(truncateDecimals(parseFloat(dados.caixa_usd)).toString());
      setNauttPontos(dados.nautt_pontos);
      setEcomWidgets(widgets);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getWidgetsData();
    };
    if (ecomWidgets.length === 0) fetchData();
  }, [ecomWidgets]);

  return (
    <React.Fragment>
      <Section nauttPontos={nauttPontos} userData={userData}/>
      <Row>
        {ecomWidgets.map((item, key) => (
          <Col xl={3} md={6} key={key}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      {item.label}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    {item.label !== "Nautt Puntos" ? (
                      <span className="text-success">
                        Compra{" "}
                        <i className="fs-13 align-middle ri-arrow-right-up-line"></i>
                      </span>
                    ) : (
                      <span className="text-success">Saldo</span>
                    )}
                    <h4 className="fs-14 fw-semibold ff-secondary mb-4">
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={3}
                        />
                      </span>
                    </h4>
                  </div>
                  <div>
                    <span className="text-danger">
                      Venta{" "}
                      <i className="fs-13 align-middle ri-arrow-right-down-line"></i>
                    </span>
                    <h4 className="fs-16 fw-semibold ff-secondary mb-4">
                      <span className="counter-value" data-target="559.25">
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter2}
                          decimals={item.decimals}
                          duration={3}
                        />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
        <Col xl={3} md={6}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Cartera (USD)
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <span className="text-success">Saldo</span>
                  <h4 className={`fs-16 fw-semibold ff-secondary mb-4`}>
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix="$ "
                        end={fundosUSD}
                        decimals={2}
                        duration={3}
                      />
                    </span>
                  </h4>
                </div>
                <div className="avatar-sm flex-shrink-0 p-1">
                  <span className={"avatar-title rounded fs-3 bg-success"}>
                    <i className="bx bx-wallet"></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ModalSaque modal={modal} toggle={toggleModal} />
    </React.Fragment>
  );
};

export default Widgets;
