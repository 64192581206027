import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrency,
} from "../../Components/FunctionsRepository";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";
import GerarLinkPagamento from "../CheckNautt/GerarLinkPagamento";

const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh, pdvId }) => {

  // Lists for dropdowns
  const [pdvs, setPdvs] = useState([]);
  const [atendentes, setAtendentes] = useState([]);

  // Selected values
  const [pdvSelecionado, setPdvSelecionado] = useState("");
  const [atendenteSelecionado, setAtendenteSelecionado] = useState("");

  // Search and pagination
  const [termoBusca, setTermoBusca] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);

  const toggle = () => {
    setPdvSelecionado("");
    setAtendentes([]);
    setTermoBusca("");
    setPaginaAtual(1);
    setModal(!modal);
  };

  const fetchPdvs = useCallback(async () => {
    const response = await apiClient.get("/pdvs");
    if (response.sucesso) {
      setPdvs(response.dados || []);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const fetchAtendentes = useCallback(
    async (pdvId) => {
      setPdvSelecionado(pdvId);
      for (const pdv of pdvs) {
        if (pdv.id === parseInt(pdvId || "0")) {
          setAtendentes(pdv.atendentes);
        }
      }
    },
    [pdvs]
  );

  useEffect(() => {
    const fetchAll = async () => {
      await fetchPdvs();
    };
    const fetchAt = async (pdvId) => {
      await fetchAtendentes(pdvId);
    };
    if (modal) {
      fetchAll();
    }
    if (pdvId) fetchAt(pdvId);
  }, [modal, paginaAtual, termoBusca, pdvId]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      size="xl"
      centered
      className="modal-custom"
    >
      <ModalHeader
        toggle={toggle}
        className="modal-title text-success bg-soft-success p-3"
      >
        Nuevo Link de Pago
      </ModalHeader>
      <ModalBody className="p-4">
        <Row className="mb-3">
          <Col md="6">
            <Label className="form-label fw-bold">PDV</Label>
            <Input
              type="select"
              value={pdvSelecionado}
              onChange={(e) => fetchAtendentes(e.target.value)}
              disabled={pdvId ? true : false}
              className="form-select shadow-sm"
            >
              <option value="">Seleccione un PDV</option>
              {pdvs.map((pdv) => (
                <option key={pdv.id} value={pdv.id}>
                  {pdv.nome}
                </option>
              ))}
            </Input>
          </Col>
          <Col md="6">
            <Label className="form-label fw-bold">Atendente</Label>
            <Input
              type="select"
              value={atendenteSelecionado}
              onChange={(e) => setAtendenteSelecionado(e.target.value)}
              required
              className="form-select shadow-sm"
            >
              <option value="">Seleccione un atendente</option>
              {atendentes.map((atendente) => (
                <option key={atendente.id} value={atendente.id}>
                  {atendente.nome}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <GerarLinkPagamento pdv_id={pdvSelecionado} atendente_id={atendenteSelecionado} setRefresh={setRefresh} />
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
