import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";

const ModalApagarConta = ({ modal, setModal, conta, setRefresh }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    const data = {
      conta_id: conta.id,
    };

    const apiClient = new APIClient();
    const response = await apiClient.delete(`/contas-cliente`, data);

    if (response.sucesso) {
      toast.success("Cuenta bancaria eliminada con éxito");
      setRefresh(true);
      setModal(false);
    } else {
      toast.error(response.mensagem || "Error al eliminar la cuenta bancaria");
    }

    setLoading(false);
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)} centered>
      <ModalHeader toggle={() => setModal(false)}>
        Eliminar Cuenta Bancaria
      </ModalHeader>
      <ModalBody>
        <p>¿Está seguro que desea eliminar esta cuenta bancaria?</p>
        <p>
          <strong>Banco:</strong> {conta?.banco}
          <br />
          <strong>Agencia:</strong> {conta?.agencia}
          <br />
          <strong>Cuenta:</strong> {conta?.conta}
        </p>
        <div className="text-end mt-4">
          <Button type="button" color="light" onClick={() => setModal(false)} className="me-2">
            Cancelar
          </Button>
          <Button type="button" color="danger" onClick={handleDelete} disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Eliminar"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalApagarConta;
