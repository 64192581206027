import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api_helper";
import { label } from "yet-another-react-lightbox";

const Navdata = () => {
  const history = useNavigate();
  const [userData, setUserData] = useState({});
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isGerarQrCode, setIsGerarQrCode] = useState("GerarOrdemPix");
  const [isOrdensPix, setIsOrdensPix] = useState("OrdensPix");
  const [isUsuarios, setIsUsuarios] = useState("Usuarios");
  const [isVerificacoes, setIsVerificacoes] = useState("Verificacoes");
  const [isPdv, setIsPdv] = useState("PontosVenda");
  const [isAtendentes, setIsAtendentes] = useState("Atendentes");
  const [isProdutos, setIsProdutos] = useState("Produtos");
  const [isLinksPagamento, setIsLinksPagamento] = useState("LinksPagamento");
  const [isRetiradas, setIsRetiradas] = useState("Retiradas");
  const [isConfigs, setIsConfigs] = useState("Configs");
  const [isInformacoes, setIsInformacoes] = useState("Informacoes");
  const [isAPI, setIsAPI] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    const getData = async () => {
      const user = await getLoggedinUser();
      setUserData(user);
    };
    getData();
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "GerarOrdemPix") {
      setIsGerarQrCode(false);
    }
    if (iscurrentState !== "OrdensPix") {
      setIsOrdensPix(false);
    }
    if (iscurrentState !== "Usuarios") {
      setIsUsuarios(false);
    }
    if (iscurrentState !== "Verificacoes") {
      setIsVerificacoes(false);
    }
    if (iscurrentState !== "PontosVenda") {
      setIsPdv(false);
    }
    if (iscurrentState !== "Atendentes") {
      setIsAtendentes(false);
    }
    if (iscurrentState !== "Produtos") {
      setIsProdutos(false);
    }
    if (iscurrentState !== "Retiradas") {
      setIsRetiradas(false);
    }
    if (iscurrentState === "LinksPagamento") {
      setIsLinksPagamento(!isLinksPagamento);
    }
    if (iscurrentState === "Configs") {
      setIsConfigs(!isConfigs);
    }
    if (iscurrentState === "Informacoes") {
      setIsInformacoes(!isInformacoes);
    }
    if (iscurrentState === "API") {
      setIsAPI(!isAPI);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isGerarQrCode,
    isOrdensPix,
    isUsuarios,
    isVerificacoes,
    isPdv,
    isAtendentes,
    isProdutos,
    isRetiradas,
    isLinksPagamento,
    isConfigs,
    isInformacoes,
    isAPI,
  ]);

  const dashboardAdmin = {
    id: "dashboard",
    label: "Dashboard",
    icon: "mdi mdi-speedometer",
    link: "/dashboard",
    stateVariables: isDashboard,
    click: function (e) {
      e.preventDefault();
      setIsDashboard(!isDashboard);
      setIscurrentState("Dashboard");
      updateIconSidebar(e);
    },
  };

  const configs = {
    id: "configs",
    label: "Configs",
    icon: "mdi mdi-account-settings",
    link: "/configs",
    stateVariables: isConfigs,
    click: function (e) {
      e.preventDefault();
      setIsConfigs(!isConfigs);
      setIscurrentState("Configs");
      updateIconSidebar(e);
    },
  };

  const verificacoes = {
    id: "verificacoes",
    label: "Verificaciones",
    icon: "mdi mdi-account-check",
    link: "/#",
    stateVariables: isVerificacoes,
    click: function (e) {
      e.preventDefault();
      setIsVerificacoes(!isVerificacoes);
      setIscurrentState("Verificacoes");
      updateIconSidebar(e);
    },
    subItems: [
      {
        id: "verificacoes-kyb",
        label: "Verificaciones KYB",
        link: "/verificacoes-kyb",
        parentId: "verificacoes",
      },
      {
        id: "verificacoes-kyc",
        label: "Verificaciones KYC",
        link: "/verificacoes-kyc",
        parentId: "verificacoes",
      },
    ],
  };

  const ordensRetiradas = [
    {
      id: "gerar-ordem-pix",
      label: "Generar Orden PIX",
      icon: "mdi mdi-qrcode",
      link: "/gerar-ordem-pix",
      stateVariables: isGerarQrCode,
      click: function (e) {
        e.preventDefault();
        setIsGerarQrCode(!isGerarQrCode);
        setIscurrentState("GerarOrdemPix");
        updateIconSidebar(e);
      },
    },
    {
      id: "ordens-pix",
      label: "Órdenes PIX",
      icon: "mdi mdi-currency-usd",
      link: "/ordens-pix",
      stateVariables: isOrdensPix,
      click: function (e) {
        e.preventDefault();
        setIsOrdensPix(!isOrdensPix);
        setIscurrentState("OrdensPix");
        updateIconSidebar(e);
      },
    },
    {
      id: "retiradas",
      label: "Retiro",
      icon: "mdi mdi-arrow-up-thick",
      link: "/retiradas",
      stateVariables: isRetiradas,
      click: function (e) {
        e.preventDefault();
        setIsRetiradas(!isRetiradas);
        setIscurrentState("Retiradas");
        updateIconSidebar(e);
      },
    },
  ];

  const usuarios = {
    id: "usuarios",
    label: "Usuarios",
    icon: "mdi mdi-account",
    link: "/usuarios",
    stateVariables: isUsuarios,
    click: function (e) {
      e.preventDefault();
      setIsUsuarios(!isUsuarios);
      setIscurrentState("Usuarios");
      updateIconSidebar(e);
    },
  };

  const apiAuth = {
    id: "api",
    label: "API",
    icon: "ri-plug-line",
    link: "/#",
    stateVariables: isAPI,
    click: function (e) {
      e.preventDefault();
      setIsAPI(!isAPI);
      setIscurrentState('API');
      updateIconSidebar(e);
    },
    subItems: [
      {
        id: "api-credenciais",
        label: "Credenciales",
        link: "/api-data",
        parentId: "api",
      },
      {
        id: "api-autenticacao",
        label: "Autenticación",
        link: "/api/autenticacao",
        parentId: "api",
      },
      {
        id: "qr-code-pix",
        label: "QR Code Pix",
        link: "/api/qr-code-pix",
        parentId: "api",
      },
    ],
  };

  const pdv = [
    {
      id: "pontos-venda",
      label: "Puntos de Venta",
      icon: "mdi mdi-store",
      link: "/pontos-venda",
      stateVariables: isPdv,
      click: function (e) {
        e.preventDefault();
        setIsUsuarios(!isPdv);
        setIscurrentState("PontosVenda");
        updateIconSidebar(e);
      },
    },
    {
      id: "atendentes",
      label: "Atendentes",
      icon: "mdi mdi-account-multiple",
      link: "/atendentes",
      stateVariables: isAtendentes,
      click: function (e) {
        e.preventDefault();
        setIsAtendentes(!isAtendentes);
        setIscurrentState("Atendentes");
        updateIconSidebar(e);
      },
    },
    {
      id: "produtos",
      label: "Produtos",
      icon: "mdi mdi-cube",
      link: "/produtos",
      stateVariables: isProdutos,
      click: function (e) {
        e.preventDefault();
        setIsProdutos(!isProdutos);
        setIscurrentState("Produtos");
        updateIconSidebar(e);
      },
    },
    {
      id: "links-pagamento",
      label: "Links de Pago",
      icon: "mdi mdi-currency-usd",
      link: "/links-pagamento",
      stateVariables: isLinksPagamento,
      click: function (e) {
        e.preventDefault();
        setIsLinksPagamento(!isLinksPagamento);
        setIscurrentState("LinksPagamento");
        updateIconSidebar(e);
      },
    },
  ];

  const informacoes = {
    id: "informacoes",
    label: "Informaciones",
    icon: "mdi mdi-information",
    link: "/informacoes",
    stateVariables: isInformacoes,
    click: function (e) {
      e.preventDefault();
      setIsInformacoes(!isInformacoes);
      setIscurrentState("Informacoes");
      updateIconSidebar(e);
    },
  };

  const menuItems =
    userData?.role === "admin"
      ? [
          {
            label: "Menú",
            isHeader: true,
          },
          dashboardAdmin,
          configs,
          ...ordensRetiradas,
          ...pdv,
          verificacoes,
          usuarios,
          informacoes,
          apiAuth,
        ]
      : userData?.role === "lojista"
      ? [
          {
            label: "Menú",
            isHeader: true,
          },
          dashboardAdmin,
          ...ordensRetiradas,
          ...pdv,
          informacoes,
          apiAuth,
        ]
      : [
          {
            label: "Menú",
            isHeader: true,
          },
          // dashboardAdmin,
          ...pdv,
          usuarios,
          informacoes,
        ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
